import React, { useState, useEffect, useCallback } from 'react';
import './Navbar.css';
import {
    MDBContainer,
    MDBNavbar,
    MDBNavbarBrand,
    MDBNavbarToggler,
    MDBIcon,
    MDBNavbarNav,
    MDBNavbarItem,
    MDBBtn,
    MDBDropdown,
    MDBDropdownToggle,
    MDBDropdownMenu,
    MDBDropdownItem,
    MDBCollapse,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
    MDBInput
} from 'mdb-react-ui-kit';

import { Link } from 'react-router-dom'; // Import React Router Link
import Logo from '../../assets/logo.png';
import Register from '../../components/auth/register/Register';
import Login from '../../components/auth/login/Login';

const Navbar = () => {
    const [openBasic, setOpenBasic] = useState(false);
    const [mode, setMode] = useState('large');
    const [loginModal, setLoginModal] = useState(false);
    const [registerModal, setRegisterModal] = useState(false);

    const toggleLoginModal = () => setLoginModal(!loginModal);
    const toggleRegisterModal = () => setRegisterModal(!registerModal);


    // Change navbar for mobile devices
    const handleResize = useCallback(() => {
        const width = window.innerWidth;

        if (width > 992) {
            setMode('large');
        } else if (width <= 992 && width > 0) {
            setMode('small');
        }
    }, []);

    useEffect(() => {
        handleResize(); // Initialize mode on component mount
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [handleResize]);

    const renderNavbar = () => {
        if (mode === 'small') {
            return (
                <>

                    {/* Login Modal */}
                    <MDBModal open={loginModal} tabIndex="-1">
                        <MDBModalDialog size="xl">
                            <MDBModalContent>
                                <MDBModalHeader>
                                    <MDBModalTitle>Logg inn</MDBModalTitle>
                                    <MDBBtn className="btn-close-light" color="none" onClick={toggleLoginModal}></MDBBtn>
                                </MDBModalHeader>
                                <MDBModalBody>
                                    <Login />
                                </MDBModalBody>
                            </MDBModalContent>
                        </MDBModalDialog>
                    </MDBModal>

                    {/* Register Modal */}
                    <MDBModal open={registerModal} tabIndex="-1">
                        <MDBModalDialog size="xl">
                            <MDBModalContent>
                                <MDBModalHeader>
                                    <MDBModalTitle>Registrer deg</MDBModalTitle>
                                    <MDBBtn className="btn-close btn-close-white" color="none" onClick={toggleRegisterModal}></MDBBtn>
                                </MDBModalHeader>
                                <MDBModalBody>
                                    <Register />
                                </MDBModalBody>
                            </MDBModalContent>
                        </MDBModalDialog>
                    </MDBModal>


                <MDBNavbar expand='lg' className='p-0 nav-wrapper m-0 w-100 text-light'>
                    <MDBContainer fluid className='nav-bg' style={{ maxWidth: '992px' }}>
                        <MDBNavbarToggler
                            aria-controls='navbarSupportedContent'
                            aria-expanded='false'
                            aria-label='Toggle navigation'
                            onClick={() => setOpenBasic(!openBasic)}
                        >
                            <MDBIcon icon='bars' fas color='light' />
                        </MDBNavbarToggler>

                        <div className="w-100" style={{ display: 'contents' }}>
                            <MDBNavbarBrand>
                                <Link to="/">
                                    <img src={Logo} alt="" height="64px" />
                                </Link>
                            </MDBNavbarBrand>

                            <MDBCollapse navbar open={openBasic}>
                                <MDBNavbarNav className='mr-auto mb-2 mb-lg-0 justify-content-center'>
                                        <MDBNavbarItem>
                                            <a className="nav-link text-light" href="#hjem">Hjem</a>
                                        </MDBNavbarItem>
                                        <MDBNavbarItem>
                                            <a className="nav-link text-light" href='#om-oss'>Om oss</a>
                                        </MDBNavbarItem>
                                        <MDBNavbarItem>
                                            <a className="nav-link text-light" href='#kontakt'>Kontakt</a>
                                        </MDBNavbarItem>
                                        <MDBNavbarItem>
                                            <a className="nav-link text-light" href='#priser'>Produkter</a>
                                        </MDBNavbarItem>

                                    {/* <MDBNavbarItem>
                                        <MDBDropdown>
                                            <MDBDropdownToggle tag='a' className='nav-link text-light' role='button'>
                                                SYSTEMER
                                            </MDBDropdownToggle>
                                            <MDBDropdownMenu dark>
                                                <MDBDropdownItem>
                                                    <Link to="/selvhjelp" className="dropdown-item">SELVHJELP</Link>
                                                </MDBDropdownItem>
                                                <MDBDropdownItem>
                                                    <Link to="/another-action" className="dropdown-item">Another action</Link>
                                                </MDBDropdownItem>
                                                <MDBDropdownItem>
                                                    <Link to="/something-else" className="dropdown-item">Something else</Link>
                                                </MDBDropdownItem>
                                            </MDBDropdownMenu>
                                        </MDBDropdown>
                                    </MDBNavbarItem> */}


                                    <div className="d-flex flex-row justify-content-start my-3 align-items-center" style={{minWidth: '200px'}}>
                                            <MDBBtn onClick={toggleLoginModal} color="secondary" outline size="sm" className="mx-2">
                                                Logg inn
                                            </MDBBtn>
                                            <Link to="/register">
                                                <MDBBtn color="primary" size="sm">
                                                    Registrer
                                                </MDBBtn>
                                            </Link>
                                    </div>
                                </MDBNavbarNav>
                            </MDBCollapse>
                        </div>
                    </MDBContainer>
                </MDBNavbar>
                </>
            );
        } else if (mode === 'large') {
            return (
                <>
                    {/* Login Modal */}
                    <MDBModal open={loginModal} tabIndex="-1">
                        <MDBModalDialog size="xl">
                            <MDBModalContent>
                                <MDBModalHeader>
                                    <MDBModalTitle className="text-light">Logg inn</MDBModalTitle>
                                    <MDBBtn className="btn-close btn-close-white" color="none" onClick={toggleLoginModal}></MDBBtn>
                                </MDBModalHeader>
                                <MDBModalBody>
                                    <Login />
                                </MDBModalBody>
                            </MDBModalContent>
                        </MDBModalDialog>
                    </MDBModal>

                    {/* Register Modal */}
                    <MDBModal open={registerModal} tabIndex="-1">
                        <MDBModalDialog size="xl">
                            <MDBModalContent>
                                <MDBModalHeader>
                                    <MDBModalTitle className="text-light">Registrer deg</MDBModalTitle>
                                    <MDBBtn className="btn-close btn-close-white" color="none" onClick={toggleRegisterModal}></MDBBtn>
                                </MDBModalHeader>
                                <MDBModalBody>
                                    <Register />
                                </MDBModalBody>
                            </MDBModalContent>
                        </MDBModalDialog>
                    </MDBModal>


                <MDBNavbar expand='lg' className='p-0 nav-wrapper-large m-0 w-100 text-light'>
                    <MDBContainer className='nav-bg-large' style={{ maxWidth: '992px' }}>
                        <MDBNavbarBrand>
                            <Link to="/">
                                <img src={Logo} alt="" height="100px" />
                            </Link>
                        </MDBNavbarBrand>
                        <MDBNavbarNav className='mr-auto mb-2 mb-lg-0 justify-content-center'>
                                <MDBNavbarItem>
                                    <a className="nav-link text-light" href="#hjem">Hjem</a>
                                </MDBNavbarItem>
                                <MDBNavbarItem>
                                    <a className="nav-link text-light" href='#om-oss'>Om oss</a>
                                </MDBNavbarItem>
                                <MDBNavbarItem>
                                    <a className="nav-link text-light" href='#kontakt'>Kontakt</a>
                                </MDBNavbarItem>
                                <MDBNavbarItem>
                                    <a className="nav-link text-light" href='#priser'>Produkter</a>
                                </MDBNavbarItem>
                        </MDBNavbarNav>
                    </MDBContainer>
                        <div className="d-flex flex-row justify-content-end align-items-center me-5" style={{ minWidth: 'fit-content' }}>
                        <MDBBtn onClick={toggleLoginModal} color="secondary" outline size="sm" className="mx-2">
                            Logg inn
                        </MDBBtn>
                            <Link to="/register">
                                <MDBBtn color="primary" className='text-light' size="sm">
                                    Registrer
                                </MDBBtn>
                            </Link>
                        </div>
                </MDBNavbar>
                </>
            );
        }
    };

    return renderNavbar();
};

export default Navbar;
