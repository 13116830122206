import React, { useState } from "react";
import {
    MDBInput,
    MDBTextArea,
    MDBCheckbox,
    MDBFile,
    MDBBtn,
} from "mdb-react-ui-kit";

const ProjectForm = ({ customer }) => {
    const [formData, setFormData] = useState({
        companyName: customer.companyName || "",
        contactPerson: customer.contactPerson || "",
        phone: customer.phone || "",
        email: customer.email || "",
        website: customer.website || "",
        projectDescription: "",
        targetAudience: "",
        projectGoals: "",
        features: [],
        primaryColor: "",
        secondaryColor: "",
        logo: null,
        domain: "",
        hosting: "",
        integrations: "",
        launchDate: "",
        budget: "",
        additionalInfo: "",
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            features: checked
                ? [...prevState.features, name]
                : prevState.features.filter((feature) => feature !== name),
        }));
    };

    const handleFileUpload = (e) => {
        setFormData({ ...formData, logo: e.target.files[0] });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("Submitted Form Data:", formData);
        // Legg til API-kall for å sende data til backend
    };

    return (
        <form onSubmit={handleSubmit} className="p-4">
            <h2 className="text-primary mb-4">Prosjektinformasjon for {customer.companyName}</h2>

            <MDBInput color="light" labelClass="text-light"
                label="Firmanavn"
                name="companyName"
                value={formData.companyName}
                onChange={handleInputChange}
                required
                className="mb-3 text-light"
            />
            <MDBInput color="light" labelClass="text-light"
                label="Kontaktperson"
                name="contactPerson"
                value={formData.contactPerson}
                onChange={handleInputChange}
                required
                className="mb-3 text-light"
            />
            <MDBInput color="light" labelClass="text-light"
                label="Telefonnummer"
                type="tel"
                name="phone"
                value={formData.phone}
                onChange={handleInputChange}
                required
                className="mb-3 text-light"
            />
            <MDBInput color="light" labelClass="text-light"
                label="E-postadresse"
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                required
                className="mb-3 text-light"
            />
            <MDBInput color="light" labelClass="text-light"
                label="Nettside"
                type="url"
                name="website"
                value={formData.website}
                onChange={handleInputChange}
                className="mb-3 text-light"
            />
            <MDBTextArea
            labelClass="text-light"
                label="Beskrivelse av prosjektet"
                name="projectDescription"
                rows="4"
                value={formData.projectDescription}
                onChange={handleInputChange}
                required
                className="mb-3 text-light"
            />
            <MDBTextArea
                label="Hvem er målgruppen?"
                name="targetAudience"
                rows="3"
                value={formData.targetAudience}
                onChange={handleInputChange}
                className="mb-3 text-light"
                labelClass="text-light"
            />
            <h5>Hvilke funksjoner trenger du?</h5>
            {["Produktsider", "Kontakt-/bestillingsskjema", "Blogg", "Betalingssystem"].map(
                (feature) => (
                    <MDBCheckbox
                        key={feature}
                        name={feature}
                        id={feature}
                        label={feature}
                        checked={formData.features.includes(feature)}
                        onChange={handleCheckboxChange}
                        className="mb-2"
                        labelClass="border-light"
                        color="light"
                    />
                )
            )}
            <MDBInput color="light" labelClass="text-light"
                label="Primærfarge (HEX)"
                name="primaryColor"
                value={formData.primaryColor}
                onChange={handleInputChange}
                className="mb-3 text-light"
            />
            <MDBInput color="light" labelClass="text-light"
                label="Sekundærfarge (HEX)"
                name="secondaryColor"
                value={formData.secondaryColor}
                onChange={handleInputChange}
                className="mb-3 text-light"
            />
            <MDBFile
                label="Last opp logo"
                id="logoUpload"
                onChange={handleFileUpload}
                className="mb-3 text-light"
            />
            <MDBInput color="light" labelClass="text-light"
                label="Lanseringsdato"
                type="date"
                name="launchDate"
                value={formData.launchDate}
                onChange={handleInputChange}
                className="mb-3 text-light"
            />
            <MDBInput color="light" labelClass="text-light"
                label="Budsjett"
                type="number"
                name="budget"
                value={formData.budget}
                onChange={handleInputChange}
                className="mb-3 text-light"
            />
            <MDBTextArea
                label="Annen relevant informasjon"
                name="additionalInfo"
                rows="4"
                value={formData.additionalInfo}
                onChange={handleInputChange}
                className="mb-3 text-light"
                labelClass="text-light"
            />
            <MDBBtn type="submit" color="primary">
                Send inn
            </MDBBtn>
        </form>
    );
};

export default ProjectForm;
