import React, { useContext, useEffect, useState } from "react";
import AdminDashboard from "./adminDashboard/AdminDasboard";
import CustomerDashboard from "./customerDashboard/CustomerDashboard";
import { UserContext } from "./context/UserContext";

const Dashboard = () => {
    const { user } = useContext(UserContext);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Hvis brukerdata allerede er lastet, avslutt lastingen
        if (user) {
            setLoading(false);
        }
    }, [user]);

    if (loading) {
        return <p>Laster brukerdata...</p>; // Viser en loader hvis brukerdata ikke er tilgjengelig
    }

    // Render riktig dashboard basert på brukerens rolle
    return (
        <div style={{ display: 'flex', minHeight: '100vh' }} className='bg-light'>
            {user.role === 'customer' ? <CustomerDashboard /> : <AdminDashboard />}
        </div>
    );
};

export default Dashboard;
