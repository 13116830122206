import React, { useEffect, useContext, useState } from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardBody, MDBTypography } from 'mdb-react-ui-kit';
import { UserContext } from '../context/UserContext'; // Importer UserContext
import { useNavigate } from 'react-router-dom';
import AdminSideNav from './components/adminSideNav/AdminSideNav';

const AdminDashboard = () => {
    const { user, setUser } = useContext(UserContext); // Hent brukerdata og setUser fra UserContext
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const storedUserId = sessionStorage.getItem('user_id');
        const storedUserRole = sessionStorage.getItem('role');
        const storedBusinessId = sessionStorage.getItem('business_id');

        if (storedUserId && storedUserRole) {
            setUser({ id: storedUserId, role: storedUserRole, businessId: storedBusinessId });
            setLoading(false);
        } else {
            navigate('/login');
        }
    }, [navigate, setUser]);
    if (loading) {
        return <p>Laster brukerdata...</p>; // Vent til brukerdata er ferdig lastet før noe vises
    }


    return (
        <MDBContainer className="mt-5 text-light d-flex" style={{height: '100vh'}}>
            <div style={{ flex: 1 }}>
            <MDBContainer className="mt-5 text-light">
            <MDBContainer className="d-flex flex-column align-items-left justify-content-center" style={{height: '20vh'}}>
                    <MDBTypography tag='h1' className='fs-1 fw-bolder '>Admin Dashboard</MDBTypography>
                    <MDBTypography tag='p' className='text-muted fw-bold'>Velkommen, {user?.role === 'admin' ? 'Administrator' : 'Utvikler'}.</MDBTypography>
                </MDBContainer>
            <MDBRow>
                <MDBCol md="4">
                    <MDBCard className="bg-light">
                        <MDBCardBody>
                            <h5>Brukerstatistikk</h5>
                            <p>Antall aktive brukere: 123</p>
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>
                <MDBCol md="4">
                    <MDBCard className="bg-light">
                        <MDBCardBody>
                            <h5>Abonnementer</h5>
                            <p>Aktive abonnementer: 56</p>
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>
                <MDBCol md="4">
                    <MDBCard className="bg-light">
                        <MDBCardBody>
                            <h5>Supportforespørsler</h5>
                            <p>Åpne saker: 12</p>
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>
            </MDBRow>
        </MDBContainer>
            </div>
        </MDBContainer>
    );
};

export default AdminDashboard;
