import { MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardBody, MDBTypography, MDBBtn, MDBIcon, MDBCardHeader } from 'mdb-react-ui-kit';
import React, { useEffect, useRef } from 'react';
import './Pricing.css';

const Pricing = () => {
        const pricingTableRef = useRef(null);

        useEffect(() => {
            if (pricingTableRef.current) {
                const script = document.createElement('script');
                script.src = 'https://js.stripe.com/v3/pricing-table.js';
                script.async = true;
                pricingTableRef.current.appendChild(script);
            }
        }, []);

        return (
            <MDBContainer fluid className='my-5' id='priser'>
                <MDBTypography tag='h3' className='fs-4 mt-5 fw-bold text-center text-light mb-4'>- Våre produkter -</MDBTypography>
                <MDBTypography tag='p' className='mt-5 fw-light text-center mx-auto text-light w-75 mb-4'>Vi leverer skreddersydde løsninger som løfter bedriften din til et nytt nivå. Enten du trenger en moderne nettside, et kraftig selvhjelpssystem eller en unik brukeropplevelse, bygger vi alt med fokus på dine behov. Hvert produkt er designet for å være funksjonelt, inspirerende og klar for vekst. Hos oss får du mer enn teknologi – du får en digital løsning som hjelper deg å skinne.</MDBTypography>
            <div className="d-flex w-100 mt-5">
            <MDBBtn color='secondary' rippleColor='light' rippleDuration={1000} outline className="text-center fw-bold text-light mx-auto">Konfigurer din egen side på 2 minutter!</MDBBtn>
                </div>
            <MDBContainer fluid className="p-0 text-light position-relative" ref={pricingTableRef} style={{ minHeight: '50vh' }}>
                <hr className="hr hr-blurry my-4" />
                <div className="my-4">
                    <stripe-pricing-table pricing-table-id="prctbl_1Qbmx309ywd7X5MphFd2MsbD"
                        publishable-key="pk_live_51PM4j109ywd7X5MpTF4GkDmXUj6irrLkKccHYYTblP9ggaFOWULQHulHY3WJ3B5vHCwUXnC1NYRqqYKL3vEy3R7z00otMQ4F9a">
                    </stripe-pricing-table>
                    </div>
            </MDBContainer>
        </MDBContainer>
        );
    };
export default Pricing;
