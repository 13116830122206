import React, { useEffect, useContext } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { UserContext } from '../../../container/dashboard/context/UserContext';

const Activate = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const { setUser } = useContext(UserContext); // Bruker UserContext for å sette brukerdata

    useEffect(() => {
        const activationCode = searchParams.get('code');

        if (!activationCode) {
            alert('Ugyldig eller manglende aktiveringskode.');
            navigate('/'); // Send til forsiden om ingen kode er tilstede
            return;
        }

        const activateAccount = async () => {
            try {
                const response = await fetch(`/api/auth/activate.php?code=${activationCode}`);
                const data = await response.json();

                if (response.ok) {
                    // Lagre brukerdata i sessionStorage
                    sessionStorage.setItem('user_id', data.user.id);
                    sessionStorage.setItem('email', data.user.email);
                    sessionStorage.setItem('first_name', data.user.first_name);
                    sessionStorage.setItem('last_name', data.user.last_name);
                    sessionStorage.setItem('role', data.user.role);

                    // Oppdater UserContext
                    setUser({
                        id: data.user.id,
                        email: data.user.email,
                        firstName: data.user.first_name,
                        lastName: data.user.last_name,
                        role: data.user.role,
                    });

                    // Naviger til dashboard
                    navigate('/dashboard');
                } else {
                    alert(data.error || 'En feil oppsto under aktivering.');
                }
            } catch (error) {
                console.error('Error during activation:', error);
                alert('En feil oppsto. Prøv igjen senere.');
            }
        };

        activateAccount();
    }, [searchParams, navigate, setUser]);

    return (
        <div style={{ textAlign: 'center', padding: '50px' }}>
            <h2>Aktiverer kontoen din...</h2>
            <p>Vennligst vent mens vi aktiverer kontoen din.</p>
        </div>
    );
};

export default Activate;
