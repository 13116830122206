import React, { useEffect, useContext } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { UserProvider, UserContext } from "./container/dashboard/context/UserContext";
import Navbar from "./container/navbar/Navbar";
import Register from "./components/auth/register/Register";
import Login from "./components/auth/login/Login";
import ProductSelector from "./components/productSelector/ProductSelector";
import SuccessPage from "./components/stripe/SuccessPage";
import CancelPage from "./components/stripe/CancelPage";
import LandingPage from "./container/landingPage/LandingPage";
import Dashboard from "./container/dashboard/Dashboard";
import DocsPage from "./container/dashboard/adminDashboard/docs/docsPage/DocsPage";
import UserManagement from "./container/dashboard/adminDashboard/components/userManagement/UserManagement";
import ConfigurePage from "./components/configurePage/ConfigurePage";
import Activate from "./components/auth/register/Activate";
import CustomerDashboard from "./container/dashboard/customerDashboard/CustomerDashboard";
import TrafficDashboard from "./container/dashboard/customerDashboard/components/TrafficDashboard";

const App = () => {
  return (
    <UserProvider>
      <Router>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route path="/products" element={<ProductSelector />} />
          <Route path="/success" element={<SuccessPage />} />
          <Route path="/cancel" element={<CancelPage />} />
          <Route path="/activate" element={<Activate />} />
          <Route path="/customer-dashboard" element={<CustomerDashboard />} />
          <Route path="/traffic" element={<TrafficDashboard />} />

          {/* Protected routes */}
          <Route path="/dashboard/*" element={<ProtectedRoutes />} />
          <Route path="docs" element={<DocsPage />} /> {/* Legg til DocsPage */}
          <Route path="user-management" element={<UserManagement />} /> {/* Legg til DocsPage */}
          <Route path="configure" element={<ConfigurePage />} /> {/* Legg til DocsPage */}

          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Router>
    </UserProvider>
  );
};

const ProtectedRoutes = () => {
  const { user } = useContext(UserContext);
  if (user === null) return <p>Laster brukerdata...</p>;
  if (!user) return <Navigate to="/login" />;
  return <Dashboard />;
};

export default App;
