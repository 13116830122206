import React, { useState, useEffect } from 'react';
import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBBtn,
} from 'mdb-react-ui-kit';

const ProductSelector = () => {
    const [products, setProducts] = useState([]);

    useEffect(() => {
        fetch('/get-products.php')
            .then((response) => response.json())
            .then((data) => setProducts(data))
            .catch((error) => console.error('Error fetching products:', error));
    }, []);

    const handlePurchase = async (priceId) => {
        try {
            const response = await fetch('/create-checkout-session.php', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ priceId }),
            });
            const { id } = await response.json();
            window.location.href = `https://checkout.stripe.com/pay/${id}`;
        } catch (error) {
            console.error('Error creating checkout session:', error);
        }
    };

    return (
        <MDBContainer className="py-5">
            <MDBRow>
                {products.map((product) => (
                    <MDBCol key={product.id} md="4" className="mb-3">
                        <MDBCard>
                            <MDBCardBody>
                                <h5>{product.name}</h5>
                                <p>{product.description}</p>
                                <p>Pris: {product.price} NOK</p>
                                <MDBBtn onClick={() => handlePurchase(product.priceId)}>Kjøp nå</MDBBtn>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                ))}
            </MDBRow>
        </MDBContainer>
    );
};

export default ProductSelector;
