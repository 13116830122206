import { MDBBtn, MDBCol, MDBContainer, MDBRow, MDBTypography } from 'mdb-react-ui-kit'
import React from 'react'
import Brand from '../../components/brand/Brand'
import './Hero.css'

const Hero = () => {
  return (
    <div id='hjem' style={{ minHeight: '80vh', backgroundImage: 'url(https://images.unsplash.com/photo-1496171367470-9ed9a91ea931?q=80&w=3570&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)' }} className='h-auto bg-image position-relative'>
      <div className="mask" style={{backgroundColor: 'rgba(0,0,0,0.85)'}}>
    <MDBContainer fluid id="hero_wrapper" className='p-5 position-relative text-light'>
            <MDBRow className='flex-column h-100' id='hero_row'>
          <MDBCol md='8' className='text-start d-flex flex-column justify-content-center w-100 h-100'>
                    <MDBTypography tag='bold' variant='h1' className='fw-bold display-3 text-uppercase mb-5' style={{zIndex: '10'}}>
                Gjøre det umulige mulig – <br /> <span className='text-rgb'>magien starter her</span>
                    </MDBTypography>
            <MDBTypography tag='h5' className='fw-normal text-secondary hero_introText' style={{ zIndex: '10' }}>
                Forvandling starter med en idé. Magien skjer når vi skaper sammen. <br />
                Vi bygger ikke bare nettsider – vi skaper opplevelser som forteller historien din og vekker begeistring. <br />
                    </MDBTypography>
            <MDBTypography tag='h5' className='fw-normal text-secondary hero_introText' style={{ zIndex: '10' }}>
                Når du velger oss, velger du mer enn teknologi, du velger lidenskap, kreativitet og en partner som forstår drømmene dine. <br />
                    </MDBTypography>
                          
                    <MDBRow className='justify-content-left flex-row mt-4 position-relative hero_buttons' style={{zIndex: '99'}}>
              <MDBBtn color='primary' className='m-2 d-flex w-auto' size='lg' style={{ zIndex: '10' }}>
                      <a href="#priser" className='text-light'>Våre løsninger</a>
                        </MDBBtn>
              <MDBBtn color='light' outline className='m-2 text-light d-flex w-auto' size='lg' style={{ zIndex: '10' }}>
                  <a href="#cta" className='text-light'>Kontakt oss</a>
                  </MDBBtn>
                    </MDBRow>
                </MDBCol>
            </MDBRow>
    </MDBContainer>
            {/* <div id="Hero-stats_wrapper" className='w-100 d-flex' style={{ zIndex: '10' }}>
              <GoogleReviews />
            </div> */}
      </div>
    </div>
  )
}

export default Hero