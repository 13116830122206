import React, { useState, useEffect } from 'react';
import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBTable,
    MDBTableHead,
    MDBTableBody,
    MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
} from 'mdb-react-ui-kit';
import ProjectForm from '../projectForm/ProjectForm';

const UserManagement = () => {
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [showModal, setShowModal] = useState(false);

    // Hent brukere fra API
    useEffect(() => {
        fetch('/api/userManagement/get-users.php')
            .then((response) => response.json())
            .then((data) => setUsers(data))
            .catch((error) => console.error('Error fetching users:', error));
    }, []);

    // Åpne modal og sette valgt bruker
    const handleEditClick = (user) => {
        setSelectedUser(user);
        setShowModal(true);
    };

    // Lukk modal
    const handleCloseModal = () => {
        setSelectedUser(null);
        setShowModal(false);
    };

    return (
        <MDBContainer className="py-5 text-light">
            <MDBRow>
                <MDBCol md="12">
                    <h1>Brukeradministrasjon</h1>
                    <p>Administrer alle registrerte brukere.</p>
                </MDBCol>
                <MDBCol md="12">
                    <MDBTable className="table-hover">
                        <MDBTableHead>
                            <tr>
                                <th>#</th>
                                <th>Navn</th>
                                <th>E-post</th>
                                <th>Rolle</th>
                                <th>Handlinger</th>
                            </tr>
                        </MDBTableHead>
                        <MDBTableBody>
                            {users.map((user, index) => (
                                <tr key={user.id}>
                                    <td>{index + 1}</td>
                                    <td>{user.name}</td>
                                    <td>{user.email}</td>
                                    <td>{user.role}</td>
                                    <td>
                                        <MDBBtn
                                            size="sm"
                                            color="primary"
                                            onClick={() => handleEditClick(user)}
                                        >
                                            Rediger
                                        </MDBBtn>
                                        <MDBBtn
                                            size="sm"
                                            color="danger"
                                            className="ms-2"
                                            onClick={() => {
                                                // Logikk for å slette brukeren
                                                console.log(`Slett bruker: ${user.id}`);
                                            }}
                                        >
                                            Slett
                                        </MDBBtn>
                                    </td>
                                </tr>
                            ))}
                        </MDBTableBody>
                    </MDBTable>
                </MDBCol>
            </MDBRow>

            {/* Modal for prosjektinformasjon */}
            <MDBModal open={showModal} setShow={setShowModal} tabIndex="-1">
                <MDBModalDialog size="lg">
                    <MDBModalContent>
                        <MDBModalHeader className='bg-light text-light'>
                            <MDBModalTitle className='text-light'>Rediger prosjektinformasjon</MDBModalTitle>
                            <MDBBtn
                                className="btn-close"
                                color="none"
                                onClick={handleCloseModal}
                            ></MDBBtn>
                        </MDBModalHeader>
                        <MDBModalBody className='bg-light text-light'>
                            {selectedUser && (
                                <ProjectForm
                                    customer={{
                                        companyName: selectedUser.name,
                                        contactPerson: selectedUser.name,
                                        phone: selectedUser.phone || "",
                                        email: selectedUser.email,
                                        website: selectedUser.website || "",
                                    }}
                                />
                            )}
                        </MDBModalBody>
                        <MDBModalFooter className='bg-light text-light'>
                            <MDBBtn color="secondary" onClick={handleCloseModal}>
                                Lukk
                            </MDBBtn>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        </MDBContainer>
    );
};

export default UserManagement;
