import React, { useState } from "react";
import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBInput,
    MDBTextArea,
    MDBCheckbox,
    MDBBtn,
    MDBRange,
    MDBTypography,
    MDBFile,
} from "mdb-react-ui-kit";

const ConfigurePage = ({ userId }) => {
    const [companyData, setCompanyData] = useState({
        companyName: "",
        organizationNumber: "",
        projectDescription: "",
        targetAudience: "Privatpersoner", // Standardverdi
        targetCustom: "",
        primaryColor: "#000000",
        secondaryColor: "#ffffff",
        logo: null,
        website: "",
        domain: "",
        budget: "0", // Standardbudsjett
    });

    const budgetOptions = [
        "0-10 000kr",
        "10-15 000kr",
        "15-25 000kr",
        "25-40 000kr",
        "40-60 000kr",
        "60-80 000kr",
        "80-100 000kr",
        "100 000kr eller mer",
    ];

    const targetAudienceOptions = [
        "Privatpersoner",
        "Små bedrifter",
        "Store bedrifter",
        "Private organisasjoner",
        "Offentlige institusjoner",
        "Annet",
    ];

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCompanyData({ ...companyData, [name]: value });
    };

    const handleFileUpload = (e) => {
        setCompanyData({ ...companyData, logo: e.target.files[0] });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append("user_id", userId);
        Object.entries(companyData).forEach(([key, value]) => {
            if (key === "logo" && value) {
                formData.append(key, value);
            } else {
                formData.append(key, value);
            }
        });

        try {
            const response = await fetch("/api/businesses/configureBusiness.php", {
                method: "POST",
                body: formData,
            });
            const result = await response.json();
            if (!response.ok) throw new Error(result.error);
            alert("Bedriften ble konfigurert!");
        } catch (error) {
            console.error("Error:", error);
            alert(`En feil oppsto: ${error.message}`);
        }
    };

    return (
        <MDBContainer fluid className="py-5 bg-light" style={{ minHeight: '100vh' }}>
            <MDBContainer>
                <h2 className="mb-4">Konfigurer ny bedrift/prosjekt</h2>
                <form onSubmit={handleSubmit} encType="multipart/form-data">
                    {/* Obligatoriske felter */}
                    <section className="mb-5">
                    <MDBRow className="mb-4">
                        <MDBCol>
                    <span className="form-label">(Obligatorisk)</span>
                            <MDBInput
                                label="Bedriftsnavn"
                                name="companyName"
                                value={companyData.companyName}
                                onChange={handleInputChange}
                                required
                            />
                        </MDBCol>
                        <MDBCol>
                                <span className="form-label">(Ikke obligatorisk)</span>
                            <MDBInput
                                label="Organisasjonsnummer"
                                name="organizationNumber"
                                value={companyData.organizationNumber}
                                onChange={handleInputChange}
                                required
                            />
                        </MDBCol>
                    </MDBRow>
                        <span className="form-label">(Obligatorisk)</span>
                    <MDBTextArea
                        label="Prosjektbeskrivelse"
                        name="projectDescription"
                        rows="4"
                        value={companyData.projectDescription}
                        onChange={handleInputChange}
                        required
                        
                    />

                        <MDBRow className="mb-5 mt-4 gy-2 flex-column">
                            <MDBCol>
                                <label htmlFor="targetAudience" className="form-label">
                                    Målgruppe
                                </label>
                                <select
                                    id="targetAudience"
                                    name="targetAudience"
                                    value={companyData.targetAudience}
                                    onChange={handleInputChange}
                                    className="form-select"
                                >
                                    {targetAudienceOptions.map((option) => (
                                        <option key={option} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </select>
                            </MDBCol>
                            {companyData.targetAudience === "Annet" && (
                                <MDBCol>
                                    <MDBInput
                                        label="Egendefinert målgruppe"
                                        name="targetCustom"
                                        value={companyData.targetCustom}
                                        onChange={handleInputChange}
                                    />
                                </MDBCol>
                            )}
                        </MDBRow>
                    </section>
                    {/* Ikke-obligatoriske felter */}
                    <hr className="hr" />
                    <section className="mb-5 mt-5">
                    <MDBTypography tag='h5' className="fw-bolder fs-5 text-uppercase">Fargetema til din bedrift</MDBTypography>
                    <span className="form-label">(ikke obligatorisk)</span>
                    <MDBRow className="mt-3 mb-5">
                        <MDBCol md='4' className="gy-5 h-auto">
                            <label htmlFor="primaryColor" className="form-label">
                                Primærfarge
                            </label>
                            <input
                                type="color"
                                id="primaryColor"
                                name="primaryColor"
                                value={companyData.primaryColor}
                                onChange={handleInputChange}
                                style={{height: '100px'}}
                                className="form-control w-50 border-0"
                            />
                        </MDBCol>
                        <MDBCol md='4' className="gy-5 h-auto">
                            <label htmlFor="secondaryColor" className="form-label">
                                Sekundærfarge
                            </label>
                            <input
                                type="color"
                                id="secondaryColor"
                                name="secondaryColor"
                                value={companyData.secondaryColor}
                                onChange={handleInputChange}
                                style={{height: '100px'}}
                                className="form-control w-50 border-0"
                            />
                        </MDBCol>
                    </MDBRow>
                    </section>
                    <hr className="hr" />
                    <section className="mb-5 mt-5">
                    <MDBTypography tag='h5' className="fw-bolder fs-5 text-uppercase">Fargetema til din bedrift</MDBTypography>
                    <label htmlFor="Nettside" className="form-label">
                        Link til din nettsode (ikke obligatorisk)
                    </label>
                    <MDBInput
                        id="Nettside"
                        label="Nettside"
                        name="website"
                        value={companyData.website}
                        onChange={handleInputChange}
                        className="mb-4"
                    />
                    <label htmlFor="Nettside" className="form-label">
                        Domene du vil ha/har (ikke obligatorisk)
                    </label>
                    <MDBInput
                        id="Domene"
                        label="Domene"
                        name="domain"
                        value={companyData.domain}
                        onChange={handleInputChange}
                        className="mb-4"
                    />
                    <MDBFile
                        type="file"
                            label="Last opp logo (Ikke obligatorisk)"
                        onChange={handleFileUpload}
                        className="mb-4"
                    />
                    </section>

                    <label htmlFor="budget" className="form-label">
                        Budsjett
                    </label>
                    <MDBRange
                        type="range"
                        id="budget"
                        name="budget"
                        value={companyData.budget}
                        onChange={handleInputChange}
                        className="form-range"
                        min="0"
                        max={budgetOptions.length - 1}
                        step="1"
                    />
                    <p>
                        Valgt budsjett:{" "}
                        <strong>{budgetOptions[parseInt(companyData.budget)]}</strong>
                    </p>

                    <MDBBtn type="submit" color="primary" className="mt-4">
                        Lagre Konfigurasjon
                    </MDBBtn>
                </form>
            </MDBContainer>
        </MDBContainer>
    );
};

export default ConfigurePage;
