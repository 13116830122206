import React, { useState, useContext } from 'react';
import { MDBContainer, MDBInput, MDBBtn, MDBRow, MDBCol, MDBTypography } from 'mdb-react-ui-kit';
import { Link, useNavigate } from 'react-router-dom';
import { UserContext } from '../../../container/dashboard/context/UserContext';

const Login = () => {
  const [formData, setFormData] = useState({ email: '', password: '' });
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const { setUser } = useContext(UserContext);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage(""); // Tilbakestill meldingen
    try {
      const response = await fetch("/api/auth/login.php", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formData),
      });

      const data = await response.json();

      if (!response.ok) {
        setMessage(data.error || "Ukjent feil.");
        return;
      }

      // Lagre brukerdata i sessionStorage
      sessionStorage.setItem("user_id", data.user_id);
      sessionStorage.setItem("role", data.role);
      sessionStorage.setItem("company_id", data.company_id);
      sessionStorage.setItem("company_name", data.company_name);

      // Oppdater UserContext
      setUser({
        id: data.user_id,
        role: data.role,
        companyId: data.company_id,
        companyName: data.company_name,
      });

      navigate("/dashboard");
    } catch (error) {
      console.error("Error during login:", error);
      setMessage("Noe gikk galt. Prøv igjen.");
    }
  };



  return (
    <MDBContainer fluid className="py-5 d-flex justify-content-center align-items-center">
      <MDBRow className="justify-content-center align-items-center w-100 h-100">
        <MDBCol md="12" className="d-flex flex-column">
          <MDBTypography tag="h5" className="text-rgb fs-2 text-uppercase fw-bolder mb-4 w-100">
            Logg deg inn på ditt kunde-dashboard!
          </MDBTypography>
          <MDBTypography tag="p" className="text-muted mb-4">
            Har du ingen bruker hos oss? Registrer deg og bestill ditt første produkt!{" "}
            <span>
              <Link className="text-light text-decoration-underline" to="/register">
                Trykk her
              </Link>{" "}
              for å registrere deg
            </span>
          </MDBTypography>
          <form onSubmit={handleSubmit}>
            <MDBInput
              label="E-post"
              type="email"
              value={formData.email}
              onChange={(e) => setFormData({ ...formData, email: e.target.value })}
              className="mb-4"
            />
            <MDBInput
              label="Passord"
              type="password"
              value={formData.password}
              onChange={(e) => setFormData({ ...formData, password: e.target.value })}
              className="mb-4"
            />
            <MDBBtn type="submit" onClick={handleSubmit} className="mx-2" size="sm">
              Logg inn
            </MDBBtn>
          </form>
          {message && <p className="mt-3 text-center">{message}</p>}
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default Login;
