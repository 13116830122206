import {
    MDBContainer,
    MDBInput,
    MDBCheckbox,
    MDBBtn,
    MDBCol,
    MDBTypography,
    MDBTextArea
} from 'mdb-react-ui-kit';

import React from 'react'


const ContactForm = () => {
    return (
        <MDBContainer fluid>
            {/* <MDBTypography variant='h5' className='text-light text-center mb-5'>
                Send en melding
            </MDBTypography> */}
            <form className='m-3'>
                <MDBInput id='form4Example1' labelClass='text-muted' className='text-muted fw-bolder' wrapperClass='mb-4' label='Navn' contrast />
                <MDBInput type='email' id='form4Example2' labelClass='text-muted' className='text-muted fw-bolder' wrapperClass='mb-4' label='E-post' contrast />
                <MDBTextArea wrapperClass='mb-4' labelClass='text-muted' className='text-muted fw-bolder' style={{ resize: 'none' }} textarea id='form4Example3' rows={5} label='Beskjed' contrast />

                <MDBCheckbox
                    wrapperClass='d-flex justify-content-start mb-4'
                    id='form4Example4'
                    label='Jeg er ikke en robot'
                    labelClass='text-light'
                />

                <MDBBtn type='submit' className='mt-5' block>
                    Send
                </MDBBtn>
            </form>
        </MDBContainer>
    )
}

export default ContactForm