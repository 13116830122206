import React, { useState, useCallback, useEffect, useContext } from 'react';
import {
    MDBSideNav,
    MDBSideNavMenu,
    MDBSideNavItem,
    MDBSideNavLink,
    MDBSideNavCollapse,
    MDBIcon,
    MDBNavbar,
    MDBContainer,
    MDBNavbarToggler,
    MDBNavbarNav,
    MDBNavbarItem,
    MDBDropdown,
    MDBDropdownToggle,
    MDBDropdownMenu,
    MDBDropdownItem,
    MDBBadge,
} from 'mdb-react-ui-kit';
import { Link, useNavigate } from 'react-router-dom';
import { UserContext } from '../../../context/UserContext';
import Logo_banner_light from '../../../../../assets/Dencker-banner-light-transparent.png';
import './CustomerSideNav.css';

const CustomerSideNav = ({ contentRef }) => {
    const [collapseOpened, setCollapseOpened] = useState('');
    const [basicOpen, setBasicOpen] = useState(true);
    const [mode, setMode] = useState('over');
    const [notifications, setNotifications] = useState([]);
    const { user, setUser } = useContext(UserContext);
    const navigate = useNavigate();

    const toggleAccordion = (value) => {
        setCollapseOpened((prev) => (prev === value ? '' : value));
    };

    const handleResize = useCallback(() => {
        if (!window.matchMedia('screen and (min-width: 1400px)').matches) {
            setMode('over');
            setBasicOpen(false);
        } else {
            setMode('push');
            setBasicOpen(true);
        }
    }, []);

    useEffect(() => {
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [handleResize]);

    useEffect(() => {
        if (contentRef?.current) {
            contentRef.current.style.transition = 'padding 0.3s linear';
            contentRef.current.style.paddingLeft = mode === 'push' && basicOpen ? '240px' : '0';
        }
    }, [contentRef, mode, basicOpen]);

    const handleLogout = () => {
        sessionStorage.clear();
        setUser(null);
        navigate('/login');
    };

    const fetchNotifications = async () => {
        try {
            const response = await fetch('/api/notifications/getNotifications.php');
            if (!response.ok) throw new Error('Feil ved henting av varsler.');
            const data = await response.json();
            setNotifications(data.notifications || []);
        } catch (error) {
            console.error('Feil ved henting av varsler:', error);
            setNotifications([]);
        }
    };

    useEffect(() => {
        fetchNotifications();
    }, []);

    return (
        <>
            <MDBSideNav small closeOnEsc mode={mode} open={basicOpen} color='dark' bgColor='primary' getOpenState={setBasicOpen}>
                <div className="d-flex justify-content-center py-4">
                    <img
                        src={Logo_banner_light}
                        height="80px"
                        alt="Dencker Logo"
                        draggable="false"
                        style={{mixBlendMode: 'color-dodge'}}
                    />
                </div>
                <MDBSideNavMenu>
                    <MDBSideNavItem>
                        <MDBSideNavLink tag={Link} to="/dashboard">
                            <MDBIcon icon="tachometer-alt" className="fa-fw me-3" color='white' />
                            Kundeportal
                        </MDBSideNavLink>
                        <MDBSideNavLink tag={Link} to="/products">
                            <MDBIcon fas icon="shopping-cart" className="fa-fw me-3" color='white' />
                            Produkter
                        </MDBSideNavLink>
                        <MDBSideNavLink tag={Link} to="/support">
                            <MDBIcon fas icon="headset" className="fa-fw me-3" color='white' />
                            Kundesupport
                        </MDBSideNavLink>
                        <MDBSideNavItem className="mt-auto">
                            <MDBSideNavLink onClick={handleLogout}>
                                <MDBIcon fas icon="sign-out-alt" className="fa-fw me-3" color='white' />
                                Logg ut
                            </MDBSideNavLink>
                        </MDBSideNavItem>
                    </MDBSideNavItem>
                    <hr className="mb-3" />
                    <span className="sidenav-subheading text-white mt-3">Produkter</span>
                    <MDBSideNavItem>
                        <MDBSideNavLink
                            icon="angle-down"
                            shouldBeExpanded={collapseOpened === 'accordionCollapse1'}
                            onClick={() => toggleAccordion('accordionCollapse1')}
                        >
                            <MDBIcon icon="heart" className="fa-fw me-3" color='white' />
                            Mine produkter
                        </MDBSideNavLink>
                                <MDBSideNavItem>
                                    <MDBSideNavLink tag={Link} to="/addons" className='mt-3'>
                                        <MDBIcon fas icon="plus" className="fa-fw me-3" color='white' />
                                        Butikk
                                    </MDBSideNavLink>
                                </MDBSideNavItem>
                        <MDBSideNavCollapse open={collapseOpened === 'accordionCollapse1'}>
                            <MDBSideNavCollapse open={collapseOpened === 'accordionCollapse1'}>
                                <hr className="mb-3" />
                                <span className="sidenav-subheading text-white mt-3">websiteType - domainName</span>
                                <MDBSideNavLink>Hjem</MDBSideNavLink>
                                <MDBSideNavLink>SEO</MDBSideNavLink>
                                <MDBSideNavLink>Markedsføring</MDBSideNavLink>
                                <MDBSideNavLink>Betalingsoversikt</MDBSideNavLink>
                                <MDBSideNavLink>Innstillinger</MDBSideNavLink>
                            </MDBSideNavCollapse>
                            <span className="sidenav-subheading text-white mt-3">Tilleggstjenester</span>
                            <MDBSideNavLink tag={Link} to="/addons">
                                <MDBIcon fas icon="plus" className="fa-fw me-3" color='white' />
                                Tilleggstjeneste 1
                            </MDBSideNavLink>
                            <MDBSideNavLink tag={Link} to="/addons">
                                <MDBIcon fas icon="plus" className="fa-fw me-3" color='white' />
                                Tilleggstjeneste 2
                            </MDBSideNavLink>
                            <MDBSideNavLink tag={Link} to="/addons">
                                <MDBIcon fas icon="plus" className="fa-fw me-3" color='white' />
                                Tilleggstjeneste 3
                            </MDBSideNavLink>
                        </MDBSideNavCollapse>
                    </MDBSideNavItem>
                    <hr className="mb-3" />
                    <span className="sidenav-subheading text-white mt-3">Brukerinnstillinger</span>
                    <MDBSideNavItem>
                        <MDBSideNavLink tag={Link} to="/products">
                            <MDBIcon fas icon="shopping-cart" className="fa-fw me-3" color='white' />
                            Mine Produkter
                        </MDBSideNavLink>
                        </MDBSideNavItem>
                        <MDBSideNavItem>
                        <MDBSideNavItem>
                            <MDBSideNavLink
                                icon="angle-down"
                                shouldBeExpanded={collapseOpened === 'accordionCollapse4'}
                                onClick={() => toggleAccordion('accordionCollapse4')}
                            >
                                <MDBIcon icon="cogs" className="fa-fw me-3" color='white' />
                                Innstillinger
                            </MDBSideNavLink>
                            <MDBSideNavCollapse open={collapseOpened === 'accordionCollapse4'}>
                                <MDBSideNavLink>Profil</MDBSideNavLink>
                                <MDBSideNavLink>Innstillinger</MDBSideNavLink>
                            </MDBSideNavCollapse>
                        </MDBSideNavItem>
                    </MDBSideNavItem>
                </MDBSideNavMenu>
            </MDBSideNav>

            <MDBNavbar fixed="top" bgColor="primary" expand="xxl">
                <MDBContainer fluid>
                    <MDBNavbarToggler
                        aria-label="Toggle navigation"
                        onClick={() => setBasicOpen(!basicOpen)}
                    >
                        <MDBIcon icon="bars" fas color="light" />
                    </MDBNavbarToggler>

                    <MDBNavbarNav right fullWidth={false} className="ml-auto d-flex flex-row">
                        <MDBNavbarItem>
                            <MDBDropdown>
                                <MDBDropdownToggle tag="a" className="nav-link text-light">
                                    <MDBIcon icon="bell" />
                                    <MDBBadge notification color="danger" pill>
                                        {notifications.length}
                                    </MDBBadge>
                                </MDBDropdownToggle>
                                <MDBDropdownMenu>
                                    {notifications.length > 0 ? (
                                        notifications.map((notification, index) => (
                                            <MDBDropdownItem key={index} link>
                                                {notification.message}
                                            </MDBDropdownItem>
                                        ))
                                    ) : (
                                        <MDBDropdownItem link>Ingen varsler</MDBDropdownItem>
                                    )}
                                </MDBDropdownMenu>
                            </MDBDropdown>
                        </MDBNavbarItem>
                        <MDBNavbarItem>
                            <MDBDropdown>
                                <MDBDropdownToggle tag="a" className="nav-link text-light">
                                    <img
                                        src="https://mdbootstrap.com/img/Photos/Avatars/img (31).webp"
                                        className="rounded-circle"
                                        height="22"
                                        alt=""
                                    />
                                </MDBDropdownToggle>
                                <MDBDropdownMenu>
                                    <MDBDropdownItem link>Profil</MDBDropdownItem>
                                    <MDBDropdownItem link onClick={handleLogout}>
                                        Logg ut
                                    </MDBDropdownItem>
                                </MDBDropdownMenu>
                            </MDBDropdown>
                        </MDBNavbarItem>
                    </MDBNavbarNav>
                </MDBContainer>
            </MDBNavbar>
        </>
    );
};

export default CustomerSideNav;
