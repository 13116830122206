import React, { createContext, useState, useEffect } from 'react';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const [user, setUser] = useState(null); // Initialverdi null for lasting

    useEffect(() => {
        const storedUserId = sessionStorage.getItem('user_id');
        const storedUserRole = sessionStorage.getItem('role');
        const storedBusinessId = sessionStorage.getItem('business_id');

        if (storedUserId && storedUserRole) {
            setUser({ id: storedUserId, role: storedUserRole, businessId: storedBusinessId });
        }
    }, []);

    return (
        <UserContext.Provider value={{ user, setUser }}>
            {children}
        </UserContext.Provider>
    );
};
