import React from 'react';
import { MDBBtn, MDBContainer } from 'mdb-react-ui-kit';
import Navbar from '../navbar/Navbar';
import Hero from '../hero/Hero';
import Customers from '../customers/Customers';
import Features from '../features/Features';
import Footer from '../footer/Footer';
import Pricing from '../../components/pricing/Pricing';

function LandingPage() {
    return (
        <>
<section className='section'>
            <Navbar/>
            </section>
<section className='section mb-5'>
            <Hero />
            </section>
<section className='section my-5'>
            <Features />
            </section>
<section className='section my-5'>
            <Customers />
            </section>
<section className='section my-5'>
            <Pricing />
            </section>
<section className='section my-5'>
            <Footer />
            </section>
        </>
    );
}

export default LandingPage;
