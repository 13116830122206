import React, { useState } from 'react';
import JSZip from 'jszip';
import {
    MDBBtn,
    MDBContainer,
    MDBInput,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBDropdown,
    MDBDropdownToggle,
    MDBDropdownMenu,
    MDBDropdownItem,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalBody,
    MDBModalFooter,
    MDBProgress,
    MDBProgressBar,
    MDBListGroup,
    MDBListGroupItem,
} from 'mdb-react-ui-kit';

const UploadComponentForm = ({ onComponentAdd, existingCategories = [] }) => {
    const [componentName, setComponentName] = useState('');
    const [componentTitle, setComponentTitle] = useState('');
    const [componentDescription, setComponentDescription] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('Velg komponenttype');
    const [newCategory, setNewCategory] = useState('');
    const [showNewCategoryModal, setShowNewCategoryModal] = useState(false);
    const [authorName, setAuthorName] = useState('');
    const [version, setVersion] = useState('1.0.0');
    const [files, setFiles] = useState({ jsx: null, css: null, php: null });
    const [fileNames, setFileNames] = useState([]);
    const [uploadProgress, setUploadProgress] = useState(0);



    const handleZipUpload = async (event) => {
        const file = event.target.files[0];
        if (!file || file.type !== 'application/zip') {
            alert('Vennligst last opp en gyldig .zip-fil.');
            return;
        }

        if (file.size > 5 * 1024 * 1024) {
            // Maks filstørrelse 5 MB
            alert('Filen er for stor. Maksimal størrelse er 5 MB.');
            return;
        }

        const zip = new JSZip();
        const contents = await zip.loadAsync(file);

        const newFiles = { jsx: null, css: null, php: null };
        const fileNamesList = [];

        let progress = 0;
        const totalFiles = Object.keys(contents.files).length;

        for (const relativePath of Object.keys(contents.files)) {
            const file = contents.files[relativePath];
            fileNamesList.push(relativePath);

            if (relativePath.endsWith('.jsx')) {
                newFiles.jsx = await file.async('string');
            } else if (relativePath.endsWith('.css')) {
                newFiles.css = await file.async('string');
            } else if (relativePath.endsWith('.php')) {
                newFiles.php = await file.async('string');
            }

            progress += 100 / totalFiles;
            setUploadProgress(Math.round(progress));
        }

        setFiles(newFiles);
        setFileNames(fileNamesList);
        setUploadProgress(100);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (selectedCategory === 'Velg komponenttype') {
            alert('Velg en gyldig komponenttype eller opprett en ny.');
            return;
        }

        if (!files.jsx) {
            alert('JSX-filen er obligatorisk.');
            return;
        }

        // Sett versjon til '1.0' som standard hvis ny komponent i en eksisterende gruppe
        const initialVersion = '1.0';

        const componentId = componentName.toLowerCase().replace(/\s+/g, '-');

        const newComponent = {
            id: componentId,
            title: componentTitle,
            description: componentDescription,
            author: authorName || 'Ukjent forfatter',
            version: initialVersion,
            category: selectedCategory,
            JSX_filePath: files.jsx ? `${selectedCategory}/${componentId}/v${initialVersion}/${componentId}.jsx` : null,
            CSS_filePath: files.css ? `${selectedCategory}/${componentId}/v${initialVersion}/${componentId}.css` : null,
            PHP_filePath: files.php ? `${selectedCategory}/${componentId}/v${initialVersion}/${componentId}.php` : null,
        };

        onComponentAdd(newComponent);

        setComponentName('');
        setComponentTitle('');
        setComponentDescription('');
        setSelectedCategory('Velg komponenttype');
        setAuthorName('');
        setFiles({ jsx: null, css: null, php: null });
    };


    const handleNewCategorySubmit = () => {
        if (newCategory.trim()) {
            if (existingCategories.includes(newCategory)) {
                alert('Denne komponentgruppen eksisterer allerede.');
                return;
            }
            setSelectedCategory(newCategory);
            setShowNewCategoryModal(false);
        } else {
            alert('Navnet på komponentgruppen kan ikke være tomt.');
        }
    };


    return (
        <MDBContainer className="my-4">
            <MDBCard>
                <MDBCardBody>
                    <h5>Last opp ny komponent</h5>
                    <p>ZIP-filen skal ha JSX-, CSS- og PHP-filer med samme navn som komponentnavnet.</p>
                    <form onSubmit={handleSubmit}>
                        <MDBRow className=' flex-column'>
                            <MDBCol className='mb-3' md="4">
                                <MDBInput
                                    label="Komponentnavn"
                                    value={componentName}
                                    onChange={(e) => setComponentName(e.target.value)}
                                    required
                                />
                            </MDBCol>
                            <MDBCol className='mb-3' md="4">
                                <MDBInput
                                    label="Tittel"
                                    value={componentTitle}
                                    onChange={(e) => setComponentTitle(e.target.value)}
                                    required
                                />
                            </MDBCol>
                            <MDBCol className='mb-3' md="4">
                                <MDBInput
                                    label="Beskrivelse"
                                    value={componentDescription}
                                    onChange={(e) => setComponentDescription(e.target.value)}
                                />
                            </MDBCol>
                        </MDBRow>

                        <MDBRow className="mt-3 flex-column">
                            <MDBCol className='mb-3' md="6">
                                <MDBDropdown>
                                    <MDBDropdownToggle color="primary" outline>
                                        {selectedCategory}
                                    </MDBDropdownToggle>
                                    <MDBDropdownMenu>
                                        {existingCategories.length > 0 ? (
                                            existingCategories.map((category, index) => (
                                                <MDBDropdownItem link key={index} onClick={() => setSelectedCategory(category)}>
                                                    {category}
                                                </MDBDropdownItem>
                                            ))
                                        ) : (
                                            <MDBDropdownItem disabled>
                                                Ingen komponenttyper funnet
                                            </MDBDropdownItem>
                                        )}
                                        <MDBDropdownItem divider />
                                        <MDBDropdownItem link onClick={() => setShowNewCategoryModal(true)}>
                                            + Opprett ny komponenttype
                                        </MDBDropdownItem>
                                    </MDBDropdownMenu>
                                </MDBDropdown>
                            </MDBCol>
                            <MDBCol className='mb-3' md="6">
                                <MDBInput
                                    label="Forfatter"
                                    value={authorName}
                                    onChange={(e) => setAuthorName(e.target.value)}
                                    className='mt-3'
                                />
                            </MDBCol>
                        </MDBRow>

                        <MDBRow className="mt-3 flex-column">
                            <MDBCol className='mb-3' md="6">
                                <MDBInput
                                    label="Versjon"
                                    value={version}
                                    onChange={(e) => setVersion(e.target.value)}
                                />
                            </MDBCol>
                            <MDBCol className='mb-3' md="6">
                                <input
                                    type="file"
                                    accept=".zip"
                                    onChange={handleZipUpload}
                                    className="form-control"
                                />
                            </MDBCol>
                        </MDBRow>

                        {uploadProgress > 0 && (
                            <MDBProgress className="my-3">
                                <MDBProgressBar width={uploadProgress} valuemin={0} valuemax={100}>
                                    {uploadProgress}%
                                </MDBProgressBar>
                            </MDBProgress>
                        )}

                        {fileNames.length > 0 && (
                            <MDBListGroup className="my-3">
                                <h6>Innhold i ZIP-filen:</h6>
                                {fileNames.map((name, index) => (
                                    <MDBListGroupItem key={index}>{name}</MDBListGroupItem>
                                ))}
                            </MDBListGroup>
                        )}

                        <MDBBtn type="submit" color="primary" className="mt-3">
                            Legg til komponent
                        </MDBBtn>
                    </form>
                </MDBCardBody>
            </MDBCard>

            <MDBModal open={showNewCategoryModal} toggle={() => setShowNewCategoryModal(!showNewCategoryModal)} tabIndex="-1">
                <MDBModalDialog>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <h5>Opprett ny komponenttype</h5>
                            <MDBBtn className="btn-close" color="none" onClick={() => setShowNewCategoryModal(false)}></MDBBtn>
                        </MDBModalHeader>
                        <MDBModalBody>
                            <MDBInput
                                label="Navn på ny komponenttype"
                                value={newCategory}
                                onChange={(e) => setNewCategory(e.target.value)}
                            />
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn color="secondary" onClick={() => setShowNewCategoryModal(false)}>
                                Avbryt
                            </MDBBtn>
                            <MDBBtn color="primary" onClick={handleNewCategorySubmit}>
                                Opprett
                            </MDBBtn>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>

        </MDBContainer>
    );
};

export default UploadComponentForm;
