import { MDBBtn, MDBCol, MDBContainer, MDBRow, MDBTypography } from 'mdb-react-ui-kit'
import React from 'react'
import './HeroBasic.css'

const HeroBasic = () => {
  return (
    <div id='hjem' style={{ minHeight: '800px', backgroundImage: 'url(https://images.unsplash.com/photo-1496171367470-9ed9a91ea931?q=80&w=3570&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)' }} className='bg-image position-relative'>
      <div className="mask" style={{backgroundColor: 'rgba(0,0,0,0.85)'}}>
    <MDBContainer fluid id="HeroBasic_wrapper" className='p-5 position-relative text-light'>
            <MDBRow className='flex-column h-100' id='HeroBasic_row'>
          <MDBCol md='8' className='text-start d-flex flex-column justify-content-center w-100 h-100'>
                    <MDBTypography variant='h1' className='fw-bold display-1 text-uppercase mb-5' style={{zIndex: '10'}}>
                          Fyr opp bedriften med <br /> <span className='text-rgb'>smarte systemer!</span>
                    </MDBTypography>
              <MDBTypography tag='h5' className='fw-normal text-secondary mt-3 HeroBasic_introText' style={{ zIndex: '10' }}>
              Våre innovative løsninger er laget for å <span className='fw-bold text-primary-gradient' style={{ zIndex: '10' }}>effektivisere, automatisere og gi IT-avdelingen din et løft.</span> 
                    </MDBTypography>
            <MDBTypography tag='h5' className='fw-normal text-secondary HeroBasic_introText' style={{ zIndex: '10' }}>
                          La dine systemer gjøre jobben, slik at teamet ditt kan fokusere på det som virkelig teller.
                    </MDBTypography>
                          
                    <MDBRow className='justify-content-left flex-row mt-4 position-relative HeroBasic_buttons' style={{zIndex: '99'}}>
              <MDBBtn color='primary' className='m-2 d-flex w-auto' size='lg' style={{ zIndex: '10' }}>
                      <a href="#priser" className='text-light'>Våre løsninger</a>
                        </MDBBtn>
              <MDBBtn color='light' outline className='m-2 text-light d-flex w-auto' size='lg' style={{ zIndex: '10' }}>
                  <a href="#cta" className='text-light'>Kontakt oss</a>
                  </MDBBtn>
                    </MDBRow>
                </MDBCol>
            </MDBRow>
    </MDBContainer>
            <div id="HeroBasic-stats_wrapper" className='w-100 d-flex' style={{ zIndex: '10' }}>
            </div>
      </div>
    </div>
  )
}

export default HeroBasic