import { MDBBtn, MDBCol, MDBContainer, MDBIcon, MDBRow, MDBTypography } from 'mdb-react-ui-kit';
import './Features.css';
import React from 'react';



  const Features = () => {
    
    return (
      <MDBContainer fluid className='text-light d-flex my-5' style={{ minHeight: '100vh' }} id='om-oss'>
        <MDBRow className='p-5 h-100 my-auto mt-5' id='features_row'>
        <MDBTypography tag='h3' className='w-100 fw-bolder fs-1 text-center mt-3 mb-3'>Din digitale profil er mer enn bare en nettside <br /> – det er <span className='text-rgb' style={{ lineHeight: '4rem' }}>din historie på nettet.</span></MDBTypography>
        <MDBTypography tag='p' className='text-center text-secondary mx-auto mb-4'>
          I dagens digitale verden holder det ikke lenger med en enkel nettside.
          Du trenger noe som skiller seg ut, noe som skaper en opplevelse. <br />
          Det handler om å fange oppmerksomheten og holde på den. Det handler om magi. 
        <br /> <br />
            Hos oss i Dencker forstår vi at magi ikke bare skjer – den skapes. <br />
            Vi har verktøyene, lidenskapen og kreativiteten til å forvandle din digitale profil til noe virkelig ekstraordinært. <br />
            Men for at magien skal fungere, må du tro på den – og viktigst av alt, tro på oss.
        </MDBTypography>
          <MDBTypography tag='h5' className='w-100 fw-bolder fs-5 text-center mt-3 mb-3'>Klar for å oppleve forskjellen?</MDBTypography>
          <MDBTypography tag='p' className='w-75 text-center text-secondary mx-auto mb-5'>
            Velg oss – fordi magi starter med en idé og blir til virkelighet gjennom samarbeid.
            <br />
            La oss sammen bygge noe unikt som ikke bare forteller hvem du er, men også får kundene dine til å huske deg. <br /> 
            Din historie fortjener å skinne. Vi sørger for at den gjør nettopp det.
          </MDBTypography>
          <hr className="hr-blurry hr mb-5" />
              <MDBCol md='6' className='d-flex mx-auto justify-content-center mt-5'>
                  <div className='grid align-items-center justify-content-center d-flex flex-column'>
                    <div md='12' className='flex-row w-100 d-flex py-4 px-2  bg-opacity-10 my-4 '>
              <div className='d-flex  text-white justify-content-center align-items-center rounded mx-4' style={{height: '64px', width: '64px'}}>
                              <MDBIcon className='text-secondary' fas icon="mobile-alt" size='2x' />
                        </div>
                          <div md='8' className='g-col-6 w-100 px-3'>
                        <MDBTypography variant='h5'>
                            Responsivt design
                        </MDBTypography>
                        <p className="text-muted">
                    En nettside må tilpasse seg brukeren, ikke omvendt. Vi sørger for at din digitale profil ser fantastisk ut på alle skjermer – fra mobiltelefoner til stasjonære datamaskiner. Uansett hvor kundene dine møter deg, vil de få en sømløs og profesjonell opplevelse som både imponerer og engasjerer.
                        </p>
                          </div>
                    </div>
            <hr className="hr w-100 bg-secondary hr-shine" />
                      <div md='8' className='flex-row w-100 d-flex py-4 px-2  bg-opacity-10 my-4 '>
                        <div className='d-flex  text-white justify-content-center align-items-center rounded mx-4' style={{height: '64px', width: '64px'}}>
                              <MDBIcon className='text-secondary' fas icon="adjust" size='2x' />
                        </div>
                          <div md='8' className='g-col-6 w-100 px-3'>
                        <MDBTypography variant='h5'>
                            Farger
                        </MDBTypography>
                        <p className="text-muted">
                    Farger handler om mer enn estetikk – de skaper stemninger, vekker følelser og forteller historien din. Vi velger farger som passer til din merkevare og målgruppe, og som forsterker budskapet ditt. Med en gjennomtenkt fargepalett vil vi sørge for at nettsiden din blir minneverdig og unik.
                        </p>
                          </div>
                    </div>
                    <hr className="hr w-100 bg-secondary hr-shine" />
                    <div md='8' className='flex-row w-100 d-flex py-4 px-2  bg-opacity-10 my-4 '>
                        <div className='d-flex  text-white justify-content-center align-items-center rounded mx-4' style={{height: '64px', width: '64px'}}>
                              <MDBIcon className='text-secondary' fas icon="server" size='2x' />
                        </div>
                          <div md='8' className='g-col-6 w-100 px-3'>
                        <MDBTypography variant='h5'>
                            Hastighet
                        </MDBTypography>
                        <p className="text-muted">
                    Når det kommer til brukeropplevelse, er hastighet alt. En rask nettside holder på oppmerksomheten og gir et profesjonelt inntrykk. Vi optimaliserer hver linje med kode, slik at nettsiden din laster på et øyeblikk, uansett hvor brukerne dine befinner seg. Magien skal skje uten forsinkelser.
                        </p>
                          </div>
                    </div>
            <hr className="hr w-100 bg-secondary hr-shine" />
                    <div md='8' className='flex-row w-100 d-flex py-4 px-2  bg-opacity-10 my-4 '>
                        <div className='d-flex  text-white justify-content-center align-items-center rounded mx-4' style={{height: '64px', width: '64px'}}>
                              <MDBIcon className='text-secondary' fas icon="shield-alt" size='2x' />
                        </div>
                          <div md='8' className='g-col-6 w-100 px-3'>
                        <MDBTypography variant='h5'>
                            Sikkerhet
                        </MDBTypography>
                        <p className="text-muted">
                    Din digitale tilstedeværelse må beskyttes mot uforutsette farer. Vi bygger nettsider med robuste sikkerhetsløsninger som beskytter både dataene dine og de som besøker siden din. Med kontinuerlig overvåkning og oppdateringer kan du være trygg på at din nettside står imot trusler, slik at du kan fokusere på å bygge videre på suksessen.
                        </p>
                          </div>
                    </div>
            <hr className="hr w-100 bg-secondary hr-shine" />
                </div>
            </MDBCol>
        <MDBCol md='2'
          className='p-0 text-center'
        >
          <hr className="vr h-100 visually-hidden-mobile bg-secondary hr-shine" />
        </MDBCol>
        </MDBRow>
    </MDBContainer>
  )
}

export default Features