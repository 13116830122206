import { MDBContainer, MDBTypography } from 'mdb-react-ui-kit'
import React from 'react'
import Cta from '../../components/cta/Cta'
import './Customers.css'

const Customers = () => {
  return (
    <MDBContainer fluid className='my-5' id='kontakt'>
        <MDBContainer className='text-center text-light padding-5'>
        <MDBTypography tag='h3' className='w-100 fw-bolder fs-1 text-center mt-3 mb-3'>Mer enn bare brukere <br /> – De er <span className='text-rgb' style={{ lineHeight: '4rem' }}>partnere</span></MDBTypography>
        <MDBTypography tag='p' className='w-75 text-center text-secondary mx-auto mb-2'>Med lidenskap for teknologi og en sterk forpliktelse til kvalitet, jobber vi for å levere løsninger som hjelper bedrifter med å vokse og lykkes. Hos oss er kundene våre mer enn bare brukere – de er partnere</MDBTypography>
        <MDBTypography tag='p' className='w-75 text-center text-secondary mx-auto mb-4'>
          Vi er stolte over å ha flere store og anerkjente aktører blant våre kunder, som for eksempel Akershus Fylkeskommune. <br /> De, og mange andre, stoler på våre systemer for å effektivisere og forbedre sin IT-drift. Våre løsninger er skreddersydd for å møte de høye kravene til både offentlige og private virksomheter, og vi leverer alltid kvalitet, pålitelighet og førsteklasses support.
        </MDBTypography>
        <MDBTypography tag='p' className='w-75 text-center text-secondary mx-auto mb-5'>
          - Rett og slett, <span className='text-rgb'>magisk</span>
        </MDBTypography>
          </MDBContainer>
        <Cta />
    </MDBContainer>
  )
}

export default Customers