import React, { useState, useCallback, useEffect } from 'react';
import {
    MDBSideNav,
    MDBSideNavMenu,
    MDBSideNavItem,
    MDBSideNavLink,
    MDBSideNavCollapse,
    MDBIcon,
    MDBContainer,
    MDBNavbar,
    MDBNavbarToggler,
    MDBNavbarNav,
    MDBNavbarItem,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalBody,
    MDBModalFooter,
    MDBBtn,
} from 'mdb-react-ui-kit';
import { Link } from 'react-router-dom';
import Logo_banner_light from '../../../../../assets/Dencker-banner-light-transparent.png';
import componentData from './../componentData';
import './DocsSideNav.css';
import UploadComponentForm from '../docsPage/UploadComponentForm';

const DocsSideNav = ({ setActiveCategory }) => {
    const [basicOpen, setBasicOpen] = useState(true);
    const [collapseOpened, setCollapseOpened] = useState('');
    const [mode, setMode] = useState('over');
    const [uploadModalOpen, setUploadModalOpen] = useState(false);
    const categories = componentData ? Object.keys(componentData) : [];
    

    const toggleAccordion = (value) => {
        setCollapseOpened((prev) => (prev === value ? '' : value));
    };

    const handleResize = useCallback(() => {
        if (!window.matchMedia('screen and (min-width: 1400px)').matches) {
            setMode('over');
            setBasicOpen(false);
        } else {
            setMode('over');
            setBasicOpen(false);
        }
    }, []);

    useEffect(() => {
        handleResize();
    }, [handleResize]);

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [handleResize]);

    const handleComponentAdd = (newComponent) => {
        const category = newComponent.category;
        if (!componentData[category]) {
            componentData[category] = [];
        }
        componentData[category].push(newComponent);
        setUploadModalOpen(false); // Lukk modal etter opplasting
    };
    return (
        <header>
            {/* SideNav */}
            <MDBSideNav closeOnEsc light bgColor="dark" backdrop mode={mode} open={basicOpen} getOpenState={setBasicOpen}>
                <div className="d-flex justify-content-center py-4">
                    <img
                        id="MDB-logo"
                        src={Logo_banner_light}
                        height="80px"
                        alt="Dencker Logo"
                        draggable="false"
                    />
                </div>
                <MDBSideNavMenu>
                    {/* Introduksjon */}
                    <MDBSideNavItem>
                        <MDBSideNavLink tag={Link} to="/docs#intro">
                            <MDBIcon fas icon="info-circle" className="fa-fw me-3" />
                            Kom i gang
                        </MDBSideNavLink>
                    </MDBSideNavItem>
                    <hr className="m-0" />

                    {/* Komponenter */}
                    <MDBSideNavItem>
                        <MDBSideNavLink icon="angle-down" onClick={() => toggleAccordion('componentsCollapse')}>
                            <MDBIcon fas icon="puzzle-piece" className="fa-fw me-3" />
                            Komponenter
                        </MDBSideNavLink>
                        <MDBSideNavCollapse open={collapseOpened === 'componentsCollapse'}>
                                {Object.keys(componentData).map((category) => (
                                    <MDBSideNavLink
                                        key={category}
                                        onClick={() => setActiveCategory(category)}
                                        className="text-capitalize"
                                    >
                                        <MDBIcon fas icon="folder-open" className="fa-fw me-2" />
                                        {category}
                                    </MDBSideNavLink>
                                ))}
                        </MDBSideNavCollapse>
                    </MDBSideNavItem>
                    <hr className="m-0" />

                    {/* API-dokumentasjon */}
                    <MDBSideNavItem>
                        <MDBSideNavLink
                            icon="angle-down"
                            shouldBeExpanded={collapseOpened === 'apiCollapse'}
                            onClick={() => toggleAccordion('apiCollapse')}
                        >
                            <MDBIcon fas icon="code" className="fa-fw me-3" />
                            API-dokumentasjon
                        </MDBSideNavLink>
                        <MDBSideNavCollapse className="h-auto" open={collapseOpened === 'apiCollapse'}>
                            <MDBSideNavLink tag={Link} to="/docs#authentication-api">
                                <MDBIcon fas icon="key" className="fa-fw me-2" />
                                Autentisering
                            </MDBSideNavLink>
                            <MDBSideNavLink tag={Link} to="/docs#data-api">
                                <MDBIcon fas icon="database" className="fa-fw me-2" />
                                Datahåndtering
                            </MDBSideNavLink>
                        </MDBSideNavCollapse>
                    </MDBSideNavItem>
                    <hr className="m-0" />

                    {/* Eksempler */}
                    <MDBSideNavItem>
                        <MDBSideNavLink
                            icon="angle-down"
                            shouldBeExpanded={collapseOpened === 'examplesCollapse'}
                            onClick={() => toggleAccordion('examplesCollapse')}
                        >
                            <MDBIcon fas icon="file-code" className="fa-fw me-3" />
                            Eksempler
                        </MDBSideNavLink>
                        <MDBSideNavCollapse className="h-auto" open={collapseOpened === 'examplesCollapse'}>
                            <MDBSideNavItem>
                                <MDBSideNavLink
                                    icon="angle-down"
                                    shouldBeExpanded={collapseOpened === 'componentsCollapse'}
                                    onClick={() => toggleAccordion('componentsCollapse')}
                                >
                                    <MDBIcon fas icon="puzzle-piece" className="fa-fw me-3" />
                                    Komponenter
                                </MDBSideNavLink>
                                <MDBSideNavCollapse className="h-auto" open={collapseOpened === 'componentsCollapse'}>
                                    {Object.keys(componentData).map((category) => (
                                        <MDBSideNavLink
                                            key={category}
                                            onClick={() => setActiveCategory(category)}
                                            className="text-capitalize"
                                        >
                                            <MDBIcon fas icon="folder-open" className="fa-fw me-2" />
                                            {category}
                                        </MDBSideNavLink>
                                    ))}
                                </MDBSideNavCollapse>
                            </MDBSideNavItem>
                        </MDBSideNavCollapse>
                    </MDBSideNavItem>
                    <hr className="m-0" />

                    {/* Tilbake til Dashboard */}
                    <MDBSideNavItem>
                        <MDBSideNavLink tag={Link} to="/dashboard">
                            <MDBIcon fas icon="arrow-left" className="fa-fw me-3" />
                            Tilbake til Dashboard
                        </MDBSideNavLink>
                    </MDBSideNavItem>
                </MDBSideNavMenu>
            </MDBSideNav>

            {/* Top Navbar */}
            <MDBNavbar fixed="top" bgColor="dark" light expand="xxl" id="main-navbar">
                <MDBContainer fluid>
                    <MDBNavbarToggler
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                        className="sideNavToggle"
                        onClick={() => setBasicOpen(!basicOpen)}
                    >
                        <MDBIcon icon="bars" fas color="light" />
                    </MDBNavbarToggler>

                    <MDBNavbarNav right fullWidth={false} className="ml-auto d-flex justify-content-center flex-row">
                        <MDBNavbarItem className='mx-4'>
                            {/* Last opp komponent knapp */}
                            <MDBBtn color="secondary" outline onClick={() => setUploadModalOpen(true)} className="w-100 text-center">
                                <MDBIcon fas icon="upload" className="me-2" />
                                Ny komponent
                            </MDBBtn>
                        </MDBNavbarItem>
                        <MDBNavbarItem className='d-flex align-items-center'>
                            <span className="nav-link text-light">
                                <MDBIcon fas icon="book" className="me-2" />
                                Dencker Dokumentasjon
                            </span>
                        </MDBNavbarItem>
                    </MDBNavbarNav>
                </MDBContainer>
            </MDBNavbar>


                        {/* Modal for opplasting av komponent */}
            <MDBModal open={uploadModalOpen} tabIndex="-1">
                <MDBModalDialog size='lg'>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <h5 className="modal-title">Last opp komponent</h5>
                            <MDBBtn className="btn-close" color="none" onClick={() => setUploadModalOpen(false)}></MDBBtn>
                        </MDBModalHeader>
                        <MDBModalBody>
                            <UploadComponentForm onComponentAdd={handleComponentAdd} existingCategories={categories} />
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn color="secondary" onClick={() => setUploadModalOpen(false)}>
                                Lukk
                            </MDBBtn>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        </header>
    );
};

export default DocsSideNav;
