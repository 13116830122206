import React, { useEffect, useState, useContext } from 'react';
import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBTypography,
    MDBBtn,
    MDBInput,
} from 'mdb-react-ui-kit';
import CustomerSideNav from '../customerDashboard/components/customerSideNav/CustomerSideNav'
import { UserContext } from '../context/UserContext';
import Typewriter from 'typewriter-effect'

const CustomerDashboard = () => {
    const [statistics, setStatistics] = useState([]);
    const [formData, setFormData] = useState({
        companyName: '',
        logo: null,
    });
    const [isProfileComplete, setIsProfileComplete] = useState(true);
    const { user } = useContext(UserContext);

    useEffect(() => {
        const checkUserInfo = async () => {
            try {
                const response = await fetch('/api/businesses/getBusinessDetails.php', {
                    headers: { 'Content-Type': 'application/json' },
                });
                const data = await response.json();

                if (!response.ok || !data.company_name) {
                    setIsProfileComplete(false);
                }
            } catch (error) {
                console.error('Error checking user information:', error);
                setIsProfileComplete(false);
            }
        };

        checkUserInfo();
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setFormData((prev) => ({ ...prev, logo: file }));
    };

    const handleSave = async () => {
        const formDataToSend = new FormData();
        formDataToSend.append('companyName', formData.companyName);
        if (formData.logo) {
            formDataToSend.append('logo', formData.logo);
        }

        try {
            const response = await fetch('/api/businesses/updateProfile.php', {
                method: 'POST',
                body: formDataToSend,
            });

            if (response.ok) {
                alert('Bedriftsinformasjonen er lagret!');
                setIsProfileComplete(true);
            } else {
                alert('Kunne ikke lagre informasjonen. Prøv igjen.');
            }
        } catch (error) {
            console.error('Error saving profile information:', error);
            alert('Noe gikk galt. Prøv igjen senere.');
        }
    };

    return (
        <MDBContainer fluid className='' style={{ height: '100vh', background:'var(--primary-dark)'}}>
            <CustomerSideNav />
            {!isProfileComplete ? (
                <MDBContainer fluid >
                    <MDBContainer className='mt-5 d-flex flex-column justify-content-center sideNav-containerRef' style={{ minHeight: '50vh' }}>
                        <h2 className="fw-bold mb-3 text-light display-5">
                        <Typewriter
                            options={{
                                loop: false,
                                autoStart: true,
                            }}
                            onInit={(typewriter) => {
                                typewriter.typeString('<span style="font-weight: 600; font-size: 3rem;">Velkommen til Dencker, </span>')
                                .pauseFor(500)
                                typewriter.typeString(user?.firstName || 'kunde')
                                typewriter.typeString('!')
                                .start();
                            }}
                        />
                        </h2>
                                    <MDBTypography tag="p" className="text-light">
                                        Siden du er ny her, trenger vi at du lager en bedrift / prosjekt.
                                        Da trenger vi ikke mer enn bare et navn. Hvis du allerede har en logo, kan du gjerne laste opp den også!
                                    </MDBTypography>
                                    <MDBInput
                                        label="Bedriftsnavn"
                                        name="companyName"
                                        value={formData.companyName}
                                        onChange={handleInputChange}
                                        required
                                        wrapperClass='mb-3'
                                        labelClass='text-light'
                                    />
                                    <div className="mb-3">
                                        <label className="form-label text-light">Logo (valgfritt)</label>
                                        <input
                                            type="file"
                                            className="form-control"
                                            onChange={handleFileChange}
                                        />
                                    </div>
                                    <MDBCol md='3'>
                                    <MDBBtn color="light" outline className='mt-4' onClick={handleSave}>
                                        Lagre
                                    </MDBBtn>
                        </MDBCol>
                    </MDBContainer>
                </MDBContainer>
            ) : (
                <MDBContainer fluid className="my-5">
                    <MDBContainer className='mt-5'>
                        <h2 className="fw-bold text-primary">Velkommen, {user?.firstName || 'Kunde'}!</h2>
                        <MDBRow className="mt-4">
                            {statistics.map((stat, index) => (
                                <MDBCol md="4" key={index}>
                                    <MDBCard className="shadow-sm">
                                        <MDBCardBody>
                                            <MDBTypography tag="h4" className="fw-bold text-center">
                                                <i className={`fas fa-${stat.icon} me-2`}></i>
                                                {stat.title}
                                            </MDBTypography>
                                            <MDBTypography tag="h1" className="text-center text-primary">
                                                {stat.count}
                                            </MDBTypography>
                                        </MDBCardBody>
                                    </MDBCard>
                                </MDBCol>
                            ))}
                        </MDBRow>
                    </MDBContainer>
                    <MDBRow>
                        {/* Legg til flere dashbord-elementer her */}
                    </MDBRow>
                </MDBContainer>
            )}
        </MDBContainer>
    );
};

export default CustomerDashboard;
