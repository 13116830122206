import React from 'react';
import {
    MDBFooter,
    MDBContainer,
    MDBCol,
    MDBRow,
    MDBIcon,
    MDBBtn
} from 'mdb-react-ui-kit';
import './Footer.css'

const Footer = () => {
  return (
      <MDBFooter className='text-center mt-5 text-light'>
          <MDBContainer className='pt-1'>
              <hr className="hr w-50 mx-auto hr-blurry" />
              <section className='mb-4'>
                  <MDBBtn
                      rippleColor="light"
                      color='secondary'
                      floating
                      size="md"
                      className='text-secondary mx-4 mt-4'
                      href='#!'
                      role='button'
                      outline
                  >
                      <MDBIcon fab className='fab fa-facebook-f' />
                  </MDBBtn>

                  <MDBBtn
                      rippleColor="light"
                      color='secondary'
                      floating
                      size="md"
                      className='text-secondary mx-4 mt-4'
                      href='#!'
                      role='button'
                      outline
                  >
                      <MDBIcon fab className='fa-instagram' />
                  </MDBBtn>

                  <MDBBtn
                      rippleColor="light"
                      color='secondary'
                      floating
                      size="md"
                      className='text-secondary mx-4 mt-4'
                      href='#!'
                      role='button'
                      outline
                  >
                      <MDBIcon fab className='fa-linkedin' />
                  </MDBBtn>
              </section>
          </MDBContainer>
          <div className='text-center text-light p-1'>
              © 2025 Copyright - <a className='text-light' href='https://Dencker.no/'> Dencker.no</a>
              <p className="text-center">Org nummer: 934538498</p>
          </div>
      </MDBFooter>
  )
}

export default Footer