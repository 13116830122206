import React, { useState, Suspense, useEffect } from 'react';
import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBBtn,
    MDBSpinner,
    MDBTabs,
    MDBTabsItem,
    MDBTabsLink,
    MDBTabsContent,
    MDBTabsPane,
    MDBTypography,
    MDBDropdown,
    MDBDropdownItem,
    MDBDropdownToggle,
    MDBDropdownMenu,
} from 'mdb-react-ui-kit';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import DocsSideNav from '../DocsSideNav/DocsSideNav';
import componentData from './../componentData';
import './DocsPage.css';

const DocsPage = () => {
    const [activeCategory, setActiveCategory] = useState('Hero');
    const [activeComponent, setActiveComponent] = useState(null);
    const [jsxCode, setJsxCode] = useState('');
    const [cssCode, setCssCode] = useState('');
    const [phpCode, setPhpCode] = useState('');
    const [showCode, setShowCode] = useState({});
    const [activeTab, setActiveTab] = useState('jsx');
    const [selectedVersion, setSelectedVersion] = useState({});
    const requireComponent = require.context('../../../../../docsComponents', true, /\.jsx$/);

    const fetchComponentCode = async (component, version = '1.0') => {
        try {
            const filePath = `./${component.JSX_filePath.replace('1.0', version)}`;
            const module = requireComponent(filePath);
            setJsxCode(await fetchFile(`/docsComponents/${component.JSX_filePath.replace('1.0', version)}`));
            setCssCode(await fetchFile(`/docsComponents/${component.CSS_filePath?.replace('1.0', version)}`));
            setPhpCode(await fetchFile(`/docsComponents/${component.PHP_filePath?.replace('1.0', version)}`));
            return module.default || null;
        } catch (error) {
            console.error('Feil ved henting av komponent:', error);
            return null;
        }
    };

    const fetchFile = async (url) => {
        try {
            const response = await fetch(url);
            if (response.ok && response.headers.get('content-type')?.includes('text')) {
                const text = await response.text();
                const isHtml = text.toLowerCase().includes('<!doctype html') ||
                    text.toLowerCase().includes('<html') ||
                    text.toLowerCase().includes('<head') ||
                    text.toLowerCase().includes('<body');
                if (isHtml) {
                    console.warn(`Filen ved ${url} ser ut som en HTML-side, ikke kildekode.`);
                    return null; // Returner null for å indikere at fanen skal skjules
                }
                return text;
            } else {
                console.warn(`Ingen gyldig respons fra ${url}`);
                return null; // Returner null hvis responsen ikke er gyldig
            }
        } catch (error) {
            console.error(`Feil ved henting av filen ${url}:`, error);
            return null; // Returner null hvis en feil oppstod
        }
    };



    const handleShowCodeToggle = (id) => {
        setShowCode((prev) => ({ ...prev, [id]: !prev[id] }));
    };

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    const handleVersionChange = async (component, version) => {
        setSelectedVersion((prev) => ({ ...prev, [component.id]: version }));
        const module = await fetchComponentCode(component, version);
        setActiveComponent((prev) => ({ ...prev, [component.id]: module }));
    };

    useEffect(() => {
        const fetchInitialComponents = async () => {
            for (const component of componentData[activeCategory] || []) {
                const module = await fetchComponentCode(component);
                setActiveComponent((prev) => ({ ...prev, [component.id]: module }));
                setSelectedVersion((prev) => ({ ...prev, [component.id]: '1.0' }));
            }
        };
        fetchInitialComponents();
    }, [activeCategory]);

    return (
        <div className="docs-page bg-light d-flex text-dark">
            <DocsSideNav setActiveCategory={setActiveCategory} />
            <MDBContainer fluid className="docs-page-container mt-5">
                <MDBRow>
                    <MDBCol>
                        <h3 className="fw-bold mt-5 text-primary ms-5 ms-1">{activeCategory} - Komponenter</h3>
                        <MDBRow className='justify-content-center'>
                            {componentData[activeCategory]?.map((component) => (
                                <MDBCol md="11" className="mb-4" key={component.id}>
                                    <MDBCard className="bg-transparent shadow-0">
                                        <MDBCardBody>
                                            <MDBTypography tag='h3' className='fs-1 fw-bolder'>{component.title}</MDBTypography>
                                            <MDBTypography tag='p' className='lead'>{component.description}</MDBTypography>
                                            {/* Forhåndsvisning av komponent */}
                                            <div className="preview-box p-1 rounded border">
                                                <Suspense fallback={<MDBSpinner color="primary">Laster forhåndsvisning...</MDBSpinner>}>
                                                    <div className="responsive-preview">
                                                        {activeComponent?.[component.id] ? (
                                                            React.createElement(activeComponent[component.id])
                                                        ) : (
                                                            <p>Komponenten kunne ikke vises.</p>
                                                        )}
                                                    </div>
                                                </Suspense>
                                            </div>
                                            <MDBRow className="mt-3 align-items-center">
                                                <MDBCol md='2' className='mx-2 justify-content-start align-items-center h-100 d-flex'>
                                                    <MDBDropdown size='md'>
                                                        <MDBDropdownToggle color="info" outline>
                                                            {selectedVersion[component.id] ? `v${selectedVersion[component.id]}` : 'Velg versjon'}
                                                        </MDBDropdownToggle>
                                                        <MDBDropdownMenu>
                                                            {(component.versions || ['1.0']).map((version) => (
                                                                <MDBDropdownItem link key={version} onClick={() => handleVersionChange(component, version)}>
                                                                    v{version}
                                                                </MDBDropdownItem>
                                                            ))}
                                                        </MDBDropdownMenu>
                                                    </MDBDropdown>
                                                </MDBCol>
                                                <MDBCol md='2' className='mx-2 justify-content-start align-items-center h-100 d-flex'>
                                                    <MDBBtn color="primary" size="md" outline onClick={() => handleShowCodeToggle(component.id)}>
                                                        {showCode[component.id] ? 'Skjul kode' : 'Vis kode'}
                                                    </MDBBtn>
                                                </MDBCol>
                                            </MDBRow>

                                            {/* Visning av tabs og kode etter klikk */}
                                            {showCode[component.id] && (
                                                <div className="mt-3">
                                                    <MDBTabs className="mb-3">
                                                        {jsxCode && (
                                                            <MDBTabsItem>
                                                                <MDBTabsLink onClick={() => handleTabClick('jsx')} active={activeTab === 'jsx'}>
                                                                    JSX
                                                                </MDBTabsLink>
                                                            </MDBTabsItem>
                                                        )}
                                                        {cssCode && (
                                                            <MDBTabsItem>
                                                                <MDBTabsLink onClick={() => handleTabClick('css')} active={activeTab === 'css'}>
                                                                    CSS
                                                                </MDBTabsLink>
                                                            </MDBTabsItem>
                                                        )}
                                                        {phpCode && (
                                                            <MDBTabsItem>
                                                                <MDBTabsLink onClick={() => handleTabClick('php')} active={activeTab === 'php'}>
                                                                    PHP
                                                                </MDBTabsLink>
                                                            </MDBTabsItem>
                                                        )}
                                                    </MDBTabs>

                                                    <MDBTabsContent>
                                                        {jsxCode && (
                                                            <MDBTabsPane open={activeTab === 'jsx'}>
                                                                <SyntaxHighlighter language="javascript" style={vscDarkPlus}>
                                                                    {jsxCode}
                                                                </SyntaxHighlighter>
                                                            </MDBTabsPane>
                                                        )}
                                                        {cssCode && (
                                                            <MDBTabsPane open={activeTab === 'css'}>
                                                                <SyntaxHighlighter language="css" style={vscDarkPlus}>
                                                                    {cssCode}
                                                                </SyntaxHighlighter>
                                                            </MDBTabsPane>
                                                        )}
                                                        {phpCode && (
                                                            <MDBTabsPane open={activeTab === 'php'}>
                                                                <SyntaxHighlighter language="php" style={vscDarkPlus}>
                                                                    {phpCode}
                                                                </SyntaxHighlighter>
                                                            </MDBTabsPane>
                                                        )}
                                                    </MDBTabsContent>
                                                </div>
                                            )}

                                        </MDBCardBody>
                                    </MDBCard>
                                </MDBCol>
                            ))}
                        </MDBRow>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        </div>
    );
};

export default DocsPage;
