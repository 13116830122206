import { MDBCol, MDBContainer, MDBRow, MDBTypography } from 'mdb-react-ui-kit'
import {React, useEffect} from 'react'
import './Brand.css'

const Brand = () => {
    
    return (
        <div key="1" className="brand-area section bg-transparent">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 offset-lg-2 col-12">
                        <div className="section-title">
                            <span className='fs-6 text-secondary shadow-sm'> Møt våre Partnere </span>
                            <h3 className="text-primary shadow-sm">Partnere</h3>
                            <p className='mt-3 text-muted'>
                                Vi er stolte over å kunne kalle oss for deres partnere 
                            </p>
                        </div>
                        <div className="clients-logos">
                            <div className="single-image">
                                <img
                                    alt="Brand Logo Images"
                                    src="https://afk.no/kunde/grafikk/bfbf0.8d5e6akershus.png"
                                />
                            </div>
                            <div className="single-image">
                                <img
                                    alt="Brand Logo Images"
                                    src="https://teoriklar.dencker.no/static/media/logo-banner.9f25bb8a51a02ce209cc.png"
                                />
                            </div>
                            <div className="single-image">
                                <img
                                    alt="Brand Logo Images"
                                    src="https://cdn.ayroui.com/1.0/images/client-logo/ayroui.svg"
                                />
                            </div>
                            <div className="single-image">
                                <img
                                    alt="Brand Logo Images"
                                    src="https://cdn.ayroui.com/1.0/images/client-logo/lineicons.svg"
                                />
                            </div>
                            <div className="single-image">
                                <img
                                    alt="Brand Logo Images"
                                    src="https://cdn.ayroui.com/1.0/images/client-logo/tailwindtemplates.svg"
                                />
                            </div>
                            <div className="single-image">
                                <img
                                    alt="Brand Logo Images"
                                    src="https://cdn.ayroui.com/1.0/images/client-logo/ecomhtml.svg"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    )
}

export default Brand