const componentData = {
    Hero: [
            {
                id: 'hero-basic',
                title: 'Basic Hero',
                description: 'En enkel hero-seksjon med tittel og undertittel.',
                JSX_filePath: 'hero/heroBasic/HeroBasic.jsx',
                CSS_filePath: 'hero/heroBasic/HeroBasic.css',
                PHP_filePath: 'hero/heroBasic/HeroBasic.php',
            },
    ],
    Navbar: [
        {
            id: 'navbar-standard',
            title: 'Standard Navbar',
            description: 'En standard navigasjonsbar med logo og lenker.',
            JSX_filePath: 'navbar/NavbarStandard.jsx',
            CSS_filePath: 'navbar/NavbarStandard.css',
            PHP_filePath: 'navbar/NavbarStandard.php',
        },
    ],

    'Fullstendige Funksjoner': [
        {
            id: 'digital-test',
            title: 'Digital Test',
            description: 'Komplett komponent for digitale tester.',
            filePath: '/components/full/DigitalTest.php', // PHP-fil
        },
    ],
};

export default componentData;
