import { MDBCol, MDBContainer, MDBRow, MDBTypography } from 'mdb-react-ui-kit'
import React from 'react'
import Brand from '../brand/Brand'
import ContactForm from '../contactForm/ContactForm'
import './Cta.css'
import Pricing from '../pricing/Pricing'

const Cta = () => {
  return (
    <MDBContainer fluid className='padding-5'>
      <Brand />
      <MDBContainer fluid className='text-center customersWrapper p-0 bg-opacity-50 glass-white box-glow'>
    <div class="padding-1">
    <MDBContainer fluid className=''>
        <MDBContainer className='' id='CTA_wrapper'>
              <MDBRow id='CTA_Row' className='p-0'>
                <MDBCol md='7' className='justify-content-center text-start d-flex flex-column' id='CTA-text_wrapper'>
                    <MDBTypography variant='h3' className='text-uppercase text-light display-6 fw-bold'>
                        Det <span className='text-decoration-underline'>beste</span> alternativet <br />
                          <span className='text-rgb'>for din bedrift</span>
                    </MDBTypography>
                    <MDBTypography variant='p' className='text-secondary w-75 my-2' id='CTA_text-p'>
                          Når du velger oss, velger du mer enn bare et IT-system – du velger en partner som forstår dine behov. Våre løsninger er utviklet for å være fleksible, skalerbare og tilpasset nettopp din bedrift. Med rask implementering, brukervennlige grensesnitt og uovertruffen kundesupport, sikrer vi at du får den beste opplevelsen fra dag én. Vi er her for å hjelpe din bedrift med å vokse og lykkes i en digital verden.
                    </MDBTypography>
                  <MDBTypography variant='p' className='text-secondary w-75 my-2' id='CTA_text-p'>
                    Usikker på hva som passer for deg? La oss hjelpe! Vi er her for å svare på alle dine spørsmål – og sammen finner vi løsningen som passer din virksomhet best."
                    </MDBTypography>
                  </MDBCol>
                <MDBCol md='5' className='rounded my-3 padding-4' id='CTA-form_wrapper'>
            <ContactForm />
                  </MDBCol>
            </MDBRow>
        </MDBContainer>
    </MDBContainer>
    </div>
    </MDBContainer>
  </MDBContainer>
  )
}

export default Cta