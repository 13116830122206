import React, { useState } from 'react';
import {
    MDBContainer,
    MDBInput,
    MDBBtn,
    MDBAlert,
    MDBTypography,
    MDBRow,
    MDBCol,
} from 'mdb-react-ui-kit';
import Logo from '../../../assets/Dencker-banner-light-transparent.png'
import Logo_icon from '../../../assets/favicon.png'
import { Link } from 'react-router-dom';
import Typewriter from 'typewriter-effect'
import './Register.css'

const Register = () => {
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        confirm_password: '',
    });

    const [errors, setErrors] = useState({
        passwordMismatch: false,
        passwordStrength: false,
    });

    const [showAlert, setShowAlert] = useState(false); // Viser melding etter registrering


    const validatePassword = (password) => {
        const regex = /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        return regex.test(password);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));

        // Valider passord
        if (name === 'password') {
            setErrors((prev) => ({
                ...prev,
                passwordStrength: !validatePassword(value),
            }));
        }

        // Valider passordbekreftelse
        if (name === 'confirm_password') {
            setErrors((prev) => ({
                ...prev,
                passwordMismatch: value !== formData.password,
            }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (errors.passwordMismatch || errors.passwordStrength) {
            alert('Vennligst rett feilene i skjemaet.');
            return;
        }

        try {
            const response = await fetch('/api/auth/register.php', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(formData),
            });
            const data = await response.json();

            if (response.ok) {
                setShowAlert(true); // Vis melding om å aktivere kontoen
            } else {
                alert(data.error || 'En feil oppsto under registreringen.');
            }
        } catch (error) {
            console.error('Error:', error);
            alert('Noe gikk galt under registreringen. Prøv igjen senere.');
        }
    };

    return (
        <MDBContainer fluid className="py-5 bg-image register-wrapper" style={{ minHeight: '100vh', backgroundImage: 'url(https://img.freepik.com/free-vector/monochrome-abstract-contour-line-illustration_53876-80532.jpg?t=st=1737236052~exp=1737239652~hmac=7c09362936893a27a250f0d6cf9cc04b13dceccf433aada6f3a0c4484625f960&w=2000)' }}>
            <div className="mask" style={{ backgroundColor: 'rgba(3,3,9,0.92)' }}>
                <div className="flex-row d-flex h-100">
                    <MDBCol md='6' className='p-3 d-flex align-items-center typewriter-wrapper'>
                        <div className="register-logo-wrapper position-absolute top-0 left-0 ms-4 mt-4" style={{ maxWidth: '300px' }}>
                            <img src={Logo} alt="" className="img-fluid" />
                        </div>
                        <MDBTypography tag='h2' className='display-2 typewriter-col' style={{minHeight: '300px'}}>
                            <Typewriter
                                options={{
                                    loop: true,
                                    autoStart: true,
                                }}
                                onInit={(typewriter) => {
                                    typewriter.typeString('<span style="font-weight: 600;">Dencker garanterer:</span></br>')
                                    typewriter.typeString('Mer <span style="color: var(--mdb-primary); font-weight: 600;">moderne</span> nettsider.')
                                        .pauseFor(500)
                                        .deleteChars(18)
                                    typewriter.typeString('<span style="color: var(--mdb-danger); font-weight: 600;">avanserte</span> websystemer.')
                                        .pauseFor(1000)
                                        .deleteChars(22)
                                    typewriter.typeString('<span style="color: var(--mdb-info); font-weight: 600;">banebrytende</span> teknologi.')
                                        .pauseFor(1000)
                                        .deleteChars(28)
                                        typewriter.typeString('<strong>Bare </strong>')
                                        .pauseFor(200)
                                        typewriter.typeString('<strong>...</strong>')
                                        .pauseFor(300)
                                        .deleteChars(3)
                                        typewriter.typeString(' gode priser.')
                                        .pauseFor(500)
                                    typewriter.typeString('<span style="color: var(--mdb-warning); font-weight: 600;"> Alltid!</span>')
                                        .pauseFor(1500)
                                        .deleteChars(26)
                                        typewriter.typeString(' En god')
                                        .pauseFor(500)
                                        .deleteChars(3)
                                        typewriter.typeString('bedre')
                                        .pauseFor(500)
                                        .deleteChars(9)
                                    typewriter.typeString(' Den <span style="color: var(--mdb-success); font-weight: 600;">beste</span> dealen for deg!')
                                        .pauseFor(1000)
                                        .deleteAll()
                                        .start();
                                }}
                            />
                            </MDBTypography>
                    </MDBCol>
                    <MDBCol md='6' className='d-flex justify-content-start shadow-md flex-column text-center register-form-wrapper p-5' style={{ background: 'rgba(0,0,0,0.5)'}}>
                            <div className="logo-wrapper mx-auto d-flex justify-content-between" style={{ maxWidth: '150px' }}>
                            <img src={Logo_icon} alt="" className="img-fluid" />
                            </div>
                        <div className='d-flex justify-content-center flex-column mt-5'>
                            <MDBTypography tag="h5" className="fs-3 text-uppercase fw-bolder mb-4 w-100">
                                Så hyggelig at du vil bli en del av <br />
                                Dencker familien
                            </MDBTypography>
                            <MDBTypography tag="p" className="text-muted mb-4">
                                Har du allerede en bruker? <br />
                                <span>
                                    For å logge inn, {" "}
                                    <Link className="text-light text-decoration-underline" to="/login">
                                        trykk her
                                    </Link>
                                </span>
                            </MDBTypography>
                            </div>
                        {showAlert ? (
                            <MDBAlert open={showAlert} color="success" dismiss>
                                Registreringen var vellykket! Sjekk e-posten din for å aktivere kontoen din.
                            </MDBAlert>
                        ) : (
                                <div className='d-flex flex-column justify-content-end flex-fill'>
                                <form onSubmit={handleSubmit}>
                                    <MDBInput
                                        wrapperClass="mb-4"
                                        label="Fornavn"
                                        name="first_name"
                                        value={formData.first_name}
                                        onChange={handleChange}
                                        required
                                    />
                                    <MDBInput
                                        wrapperClass="mb-4"
                                        label="Etternavn"
                                        name="last_name"
                                        value={formData.last_name}
                                        onChange={handleChange}
                                        required
                                    />
                                    <MDBInput
                                        wrapperClass="mb-4"
                                        label="E-post"
                                        type="email"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                        required
                                    />
                                    <MDBInput
                                        wrapperClass="mb-2"
                                        label="Passord"
                                        type="password"
                                        name="password"
                                        value={formData.password}
                                        onChange={handleChange}
                                        required
                                    />
                                    {errors.passwordStrength && (
                                        <p className="text-danger text-start">
                                            Passordet må inneholde minst 8 tegn, én stor bokstav, ett tall og ett spesialtegn.
                                        </p>
                                    )}
                                    <MDBInput
                                        wrapperClass="mb-4 mt-4"
                                        label="Bekreft passord"
                                        type="password"
                                        name="confirm_password"
                                        value={formData.confirm_password}
                                        onChange={handleChange}
                                        required
                                    />
                                    {errors.passwordMismatch && (
                                        <p className="text-danger">Passordene samsvarer ikke.</p>
                                    )}
                                    <MDBBtn type="submit" color="primary" className="mt-3 text-light">
                                        Registrer
                                    </MDBBtn>
                                </form>
                            </div>
                        )}
                    </MDBCol>
                </div>
            </div>
        </MDBContainer>
    );
};

export default Register;
